import TagManager from "react-gtm-module";
import Cookies from "universal-cookie";
import { providerMap } from "../app/app.types";
import { getTokenData } from "../utils/localStorageService";
import { AppRoutes } from "../app/routes";
import { DeviceType } from "../features/user/userTypes";
import {
  hasExtension,
  userDeviceSiganl,
  userIsAmdinSginal,
} from "../features/user/userSignals";
import { Queries, useTypedQueryData } from "../lib/react-query";
import { Asset } from "../features/adminFeatures/assets/assetTypes";
import { useHistory } from "react-router-dom";

export const useFeature = () => {
  const me = useTypedQueryData(Queries.FETCH_ME);
  const history = useHistory();
  function onlyUnique(value: any, index: number, self: any) {
    return self.indexOf(value) === index;
  }

  const shareUrl = (film: any): string => {
    return `${process.env.REACT_APP_REDIRECT}/redirect-link?url=${film.platform_url}`
  };

  const parseUrl = (url: string, category?: string, isHovered?: boolean) => {
    if (category === "See Tom Run!" || category === "Global Running Day!") {
      return url.replace(/(\.[\w\d_]+)$/i, "-tom-cruise-running.jpg");
    }
    if (category === "One does Not simply walk Into Mordor") {
      if (url.match("LOTR_The_Power_of_the_Ring")) return url;
      if (url.match("tolkien-dome-karukoski")) return url;
      return isHovered ? url : url.replace(/(\.[\w\d_]+)$/i, "-map.jpg");
    }
    if (url.includes("clearplay-web/images/")) {
      return url;
    }
    const substring = url.substring(url.lastIndexOf("/") + 1);
    return `https://d39yyq6mebmxrn.cloudfront.net/${substring}`;
  };

  async function goToLink(
    value: number,
    asset: Asset | any,
    isSearch?: boolean
  ) {
    // has extension?
    if (hasExtension.value) {
      // is logged in?
      if (await getTokenData()) {
        if (
         !me?.isActive
        ) {
         history.push(AppRoutes.ABANDONED_CHECKOUT)
        } else if (!me?.subscription && !userIsAmdinSginal.value) {
          window.location.pathname = `${AppRoutes.MEMBERSHIP_AND_PAYMENT}#restart`;
        } else {
          // got to movie page
          if (asset.asset_id === 9570 || isSearch) {
            return window.open(asset.platform_url, "_blank");
          } else {
            window.open(asset.platform_url, "_self");
            return;
          }
        }
      } else {
        // got to movie page

        return window.open(asset.platform_url, "_self");
      }
    } else if (userDeviceSiganl.value !== DeviceType.DESKTOP) {
      window.location.pathname = AppRoutes.MOBILE_EXPLAINER;
      return;
    } else {
      // no extenstion go to chrome extension store page
      const cookies = new Cookies();
      cookies.set("extension_post_install_url", `${asset.platform_url}`, {
        domain: ".clearplay.com",
      });
      window.open(
        `https://join.clearplay.com/filter-movie/?redirect_url=${asset.platform_url}`,
        "_self"
      );
    }
  }

  const assetClick = (loading: boolean, value: number, asset: Asset | any) => {
    if (loading) return;
    TagManager.dataLayer({
      dataLayer: {
        eventCategory: `web.clearplay.com | ${providerMap[value]}`,
        eventAction: "click event | all links",
        eventLabel: `${asset.display_name}`,
        event: "interactionEvent",
      },
    });
    goToLink(value, asset);
  };

  return {
    assetClick,
    goToLink,
    shareUrl,
    onlyUnique,
    parseUrl,
  };
};
