import { utcDate } from '../../utils';

interface IDateCell {
  row: any;
  value: string;
}

export const DateCell: React.FC<IDateCell> = ({ row, value }) => {
  if (row.isGrouped) return <span />;
  return <p>{value ? utcDate(value) : 'No Date'}</p>;
};
