import { useQuery } from "@tanstack/react-query";
import { signal } from "@preact/signals-react";
import { getAssetDetail } from "./asset.api";

export const movieDBSignal = signal<any>(undefined);
export const releasedAssetsSignal = signal<any>(undefined);

export const useAssetData = () => {
  // Fetch Asset Detail
  const useFetchAssetDetail = (imdbId: string) =>
    useQuery(["assetDetail", imdbId], () => getAssetDetail(imdbId), {
      enabled: false,
    });

  return {
    useFetchAssetDetail,
  };
};
