import styled from 'styled-components/macro';

export const TabPanel = styled.div`
  min-height: fit-content;
  padding-top: clamp(100px, 20vw, 140px);
  padding-top: ${props => props.messageActive ? '200px' : '180px)'} ;
`;
export const ScrollingRows = styled.div`
flex-shrink: 1;
flex-grow: 1;
`;