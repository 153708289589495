import { getFilmUrl } from "../../utils";
import { GoToLinkCell } from "../CustomCell.styles";

import { selectedTable } from "../../../features/adminFeatures/useAdminData";

interface IGoToFilmCell {
  row: any;
  value: string;
  column: any;
}

export const GoToFilmCell: React.FC<IGoToFilmCell> = ({ row, value }) => {
  const selectedAdminTable = selectedTable.value
  return (
    <GoToLinkCell
      onClick={() =>
        window.open(
          getFilmUrl(row.original.platform_id, row.original, selectedAdminTable),
          "_blank"
        )
      }
    >
      {value}
    </GoToLinkCell>
  );
};
