import styled from "styled-components/macro";

export const GridWrapper = styled.div`
  background-color: #15202b !important;
  padding: 10px;
  border-radius: 8px;
  color: white;
  font-size: 12px;
  span {
    margin-right: 10px;
  }
  p {
    padding: 2.5px;
  }
`;

export const KeyPoppupWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  h4 {
    cursor: pointer;
    text-align: center;
    margin: 0 10px;
    color: white;
  }
  flex-direction: row;
`;
