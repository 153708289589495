import { useIsMutating, useMutation, useQuery } from "@tanstack/react-query";
import { ExtractFnReturnType } from "../../lib/react-query";

import { fetchAdminLinks, fetchCustomer, searchAccounts } from "./admin.api";
import {
  CustomerSearchResult,
  FetchAdminDataQueryKeysEnum,
} from "./admin.types";
import { User } from "../user/userTypes";
import {
  LocalStorage,
  getLocalStorageItem,
} from "../../utils/localStorageService";
import { useEffect, useMemo } from "react";
import { userLoadingSignal, userMessageSignal } from "../user/userSignals";
import {signal} from '@preact/signals-react'
import { AdminTableSelection } from "./tables/tableTypes";

export const selectedTable = signal<AdminTableSelection>(AdminTableSelection.STATS)

export const useAdminData = () => {
  const customerId = getLocalStorageItem(LocalStorage.CUSTOMER_ID_EDIT);

  const isMutatingCustomer = useIsMutating({
    mutationKey: ["CustomerMutation"],
  });

  const isMutatingMemo = useMemo(
    () => isMutatingCustomer,
    [isMutatingCustomer]
  );

  type AdminCustomerQueryFnType = typeof searchAccounts;

  const useAdminCustomerQuery = (searchOption: string, inputValue: string) => {
    return useQuery<ExtractFnReturnType<AdminCustomerQueryFnType>>({
      queryKey: ["customerSearch", searchOption, inputValue],
      queryFn: async () =>
        await searchAccounts({ [searchOption]: `*${inputValue}*` }),
      onSuccess: async (data: CustomerSearchResult[]) => {
        return data;
      },
      onError: (error: any) => {
        userMessageSignal.value = {
          message: error.data.message,
          color: "red",
        };
      },
      enabled: false,
    });
  };

  type FetchAdminDataQueryFnType = typeof fetchAdminLinks;

  const useFetchAdminDataQuery = (
    queryKey: FetchAdminDataQueryKeysEnum,
    queryFn: Function
  ) => {
    return useQuery<ExtractFnReturnType<FetchAdminDataQueryFnType>>({
      queryKey: [queryKey],
      queryFn: async () => await queryFn(),
      onSuccess: async (data: any) => {
        return data;
      },
      onError: async (error: any) => {
        console.log("error fetching data", error);
      },
      enabled: false,
    });
  };

  type FetchCustomerDataQueryFnType = typeof fetchCustomer;

  const useFetchCustomer = (userId: string) => {
    return useQuery<ExtractFnReturnType<FetchCustomerDataQueryFnType>>({
      queryKey: ["customer", userId],
      queryFn: async () => await fetchCustomer(userId),
      onSuccess: async (data: User) => {
        return data;
      },
      onError: async (error: any) => {
        console.log("error fetching customer", error);
      },
      enabled: false,
    });
  };

  const { refetch: refetchCustomerData } = useFetchCustomer(customerId || "");

  type CustomerMutationResults = any;
  const useCustomerMutation = <T extends CustomerMutationResults>(
    mutationKey: ["CustomerMutation"],
    mutationFn: (params: any) => Promise<T>
  ) => {
    return useMutation({
      mutationKey: mutationKey,
      mutationFn: mutationFn,
      onSuccess: () => {
        refetchCustomerData();
      },
      onError: async (error: any) => {
        return (userMessageSignal.value = {
          message: error.data.message,
          color: "red",
        });
      },
    });
  };

  useEffect(() => {
    const newLoadingState = isMutatingMemo > 0;
    if (userLoadingSignal.value !== newLoadingState) {
      userLoadingSignal.value = newLoadingState;
    }
  }, [isMutatingMemo]);

  return {
    useAdminCustomerQuery,
    useFetchCustomer,
    useCustomerMutation,
    useFetchAdminDataQuery,
  };
};
