import { fetchAssetsForShelf, fetchInitFlix } from "./flixApi";
import {
  UseInfiniteQueryResult,
  useInfiniteQuery,
  useMutation,
} from "@tanstack/react-query";
import {
  AssetList,
  FetchFlixResponse,
} from "../adminFeatures/assets/assetTypes";
import { queryClient } from "../../lib/react-query";
import { assetPagination } from "../components/shelfComponents/shelfUtil";
import { signal } from "@preact/signals-react";

export const assetLimit = 12;
export const fetchingSignal = signal(false);
export const flixProviderSignal = signal(7);
export const isFetchingNextPageSignal = signal(false);
export const hasNextPageSignal = signal<boolean | undefined>(false);
export const shelfLengths = signal<{ [key: number]: number }>({});

export const useFlix = () => {
  const provider = flixProviderSignal.value;

  const useFlixData = (
    provider: number
  ): UseInfiniteQueryResult<FetchFlixResponse, Error> => {
    const assets_limit = assetLimit;
    const shelves_limit = 6;
    return useInfiniteQuery<FetchFlixResponse, Error>(
      ["queryFlix", provider],
      async ({ pageParam = 0 }) => {
        const shelves_offset = pageParam * shelves_limit;
        return await fetchInitFlix({
          provider,
          assets_limit,
          shelves_limit,
          shelves_offset,
        });
      },
      {
        getNextPageParam: (lastPage: any, allPages: any) => {
          if (!lastPage || lastPage?.length < shelves_limit) {
            return undefined;
          }
          return allPages.length;
        },
        select: (data) => {
          const flattenedData: any = data.pages.flatMap((page) => page);

          // Use a Set to track seen asset_list_type_ids
          const seen = new Set();
          const filtered: any = flattenedData.filter((item: AssetList) => {
            if (!seen.has(item.asset_list_type_id)) {
              seen.add(item.asset_list_type_id);
              return true; // Keep the item, it's unique so far
            }
            return false; // Skip this item, it's a duplicate
          });

          return {
            pages: filtered,
            pageParams: data.pageParams,
          };
        },
      }
    );
  };

  const useFetchAndAddAssets = (asset_list_type_id: number) => {
    const currentPagination = assetPagination.value[asset_list_type_id];
    const limit = assetLimit;
    const fetchAndAddAssets = async () => {
      const offset = currentPagination?.initialFetch?.value
        ? assetLimit
        : currentPagination?.offset?.value + limit;

      const platform_id = provider;
      return fetchAssetsForShelf({
        asset_list_type_id,
        platform_id,
        offset,
        limit,
      });
    };

    const { mutate } = useMutation(fetchAndAddAssets, {
      onSuccess: (newAssets) => {

        queryClient.setQueryData<FetchFlixResponse>(
          ["queryFlix", provider],
          (currentData) => {
            if (!currentData || !currentData.pages) {
              return { pages: [], pageParams: currentData?.pageParams || [] };
            }

            const updatedPages = currentData.pages.map((page) =>
              page.map((shelf) => {
                if (shelf.asset_list_type_id === asset_list_type_id) {
                  shelfLengths.value[asset_list_type_id] = shelf.asset_count;
                  return {
                    ...shelf,
                    assets: [...shelf.assets, ...newAssets],
                    asset_count: shelf.asset_count,
                  };
                }
                return shelf;
              })
            );
            return {
              ...currentData,
              pages: updatedPages,
            };
          }
        );

        assetPagination.value[asset_list_type_id] = {
          offset: signal(currentPagination.offset.value + limit),
          limit: signal(limit),
          initialFetch: signal(false),
          isEnd: signal(
            newAssets.length === shelfLengths.value[asset_list_type_id]
          ),
        };
      },
    });

    return mutate;
  };

  const {
    data: initFlixData,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
  } = useFlixData(provider);

  isFetchingNextPageSignal.value = isFetchingNextPage;
  hasNextPageSignal.value = hasNextPage;

  return {
    initFlixData,
    fetchNextPage,
    // isFetchingNextPage,
    // hasNextPage,
    useFetchAndAddAssets,
  };
};
