import { AssetWrapper, TopRightOverlay } from "./SliderRow.styles";
import React, { useEffect, useState } from "react";
import {
  KeyPressDetails,
  useFocusable,
} from "@noriginmedia/norigin-spatial-navigation";
import LazyImage from "../LazyImage";
import UniqueFilm from "./UniqueFilm";
import { useFeature } from "../../../hooks/useFeature";
import { userDeviceSiganl, userIsAmdinSginal, userMessageSignal } from "../../user/userSignals";
import { Asset } from "../../adminFeatures/assets/assetTypes";
import { useHistory } from "react-router-dom";
import App from "../../../App";
import { AppRoutes } from "../../../app/routes";
import { movieFilterSignal } from "../../../admin/tableComponents/TableToolBar";

interface RowImageProps {
  index: number;
  asset: Asset;
  value: number;
  parentRef: any;
  onEnterPress: (props: object, details: KeyPressDetails) => void;
  open?: boolean;
  setExpanded?: React.Dispatch<React.SetStateAction<boolean>>;
  rowTitle?: string;
  swiper?: any;
  assetIndex?: number;
  handleSlide?: Function;
}

const RowAsset = React.memo<RowImageProps>((props) => {
  const history = useHistory();
  const { shareUrl } = useFeature();
  const {
    index,
    asset,
    value,
    parentRef,
    onEnterPress,
    open,
    setExpanded,
    rowTitle,
    swiper,
    assetIndex,
    handleSlide,
  } = props;
  const key = `${index.toString() + asset.display_name}-${
    !rowTitle ? "expanded" : rowTitle
  }`;
  const [notFocusable, setNotFocusable] = useState(false);
  const isMobile = userDeviceSiganl.value === 'mobile'
  const { ref, focused } = useFocusable({
    autoRestoreFocus: true,
    trackChildren: true,
    focusKey: key,
    onEnterPress,
    extraProps: {
      value,
      asset,
  }});

  const [keyPressed, setKeyPressed] = useState('')

  const copyLink = (film: any) => {
    const url = shareUrl(film);
    try {
      navigator.clipboard.writeText(url);

    } catch (err) {
      console.log('err :>> ', err);
    }
    userMessageSignal.value = {
      color: "green",
      message: "Share link copied to clipboard",
    }
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      setKeyPressed(event.key)
    };

    document.addEventListener("keydown", handleKeyDown);

    // Cleanup the event listener when the component is unmounted
    return () => {
        document.removeEventListener("keydown", handleKeyDown);
    };
}, []);

  const handleEdgeFocus = () => {
    if (!swiper || !handleSlide) return;

    const visibleSlides = 6; // Assuming you're showing 5 slides at a time

    // Detect if the focused slide is on the left edge
    if (assetIndex === swiper.activeIndex - 1 && keyPressed === 'ArrowLeft') {
      handleSlide("prev"); // Trigger swiper to move to the previous slide
    }
    // Detect if the focused slide is on the right edge
    else if (assetIndex === swiper.activeIndex + visibleSlides - 1) {
      handleSlide("next"); // Trigger swiper to move to the next slide
    }
  };

  const handleRowFocus = () => {
    if (parentRef.current && focused && (keyPressed === 'ArrowUp' || keyPressed === 'ArrowDown')) {
      parentRef.current.scrollIntoView({
        behavior: "smooth", 
        block: "center",
      });
    }
  }

  const goToAdminAsset = () => {
    if (!userIsAmdinSginal.value) return;
    movieFilterSignal.value = asset.display_name;
    history.push(`${AppRoutes.ADMIN_DASHBOARD}#movies`);
  }

  useEffect(() => {
    if (focused) {
      handleEdgeFocus();
      handleRowFocus()
    }

  }, [focused]);

  if (asset.display_name === "uniqueFilm") {
    return <UniqueFilm ref={ref} index={index} focused={focused} film={asset} />;
  } else
    return (
      <AssetWrapper
        key={`${index} + ${asset.asset_id} + ${open} ? "expanded" : "slider-row"`}
        open={open}
        ref={ref}
        focused={focused}
        isMobile={isMobile}
      >
        {userIsAmdinSginal.value  && <TopRightOverlay onClick={goToAdminAsset} />}
        <LazyImage
          index={`${index} + "-" + ${asset.asset_id}`}
          focused={focused && !notFocusable}
          focusable={notFocusable}
          expanded={false}
          asset={asset}
          key={asset.asset_id}
          value={value}
          open={open}
          setExpanded={setExpanded}
        />
        {/* {userIsAmdinSginal.value && !asset.expand && ( */}
        {userIsAmdinSginal.value && (
          <div onClick={() => copyLink(asset)} className="share-link">
            Share
          </div>
        )}
      </AssetWrapper>
    );
});

export { RowAsset };
