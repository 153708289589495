import { MovieScoreAsset } from "../../../features/adminFeatures/assets/assetTypes";
import { Item } from "../CustomTable.styles";
import {
  DropdownScoreControl,
  ScorDropDownTable,
} from "./ScoreDropDown.styles";
import { useChangesSaved } from "../../adminHooks";
import {
  addScore,
  deleteScore,
  updateScore,
} from "../../../features/adminFeatures/assets/asset.api";
import { InputLabel } from "@mui/material";

interface IScoreDropdown {
  value?: number;
  setValue: React.Dispatch<any>;
  row: any;
  updateMyData: Function;
  column: any;
  initialValue: any;
}

export const ScoreDropDown: React.FC<IScoreDropdown> = (props) => {
  const { setMessage } = useChangesSaved();

  const { value, row, column, initialValue, setValue, updateMyData } = props;

  const onSelectChange = async (e: { target: { value: string } }) => {
    setValue(e.target.value);
    const imdbId = row.values.imdb_id;
    const asset_score_id = row.values.asset_score_id;
    const newScore = asset_score_id === null;
    const removeScore = e.target.value === "N/R";
    const score = Number(e.target.value);

    if (removeScore) {
      const res: any = await deleteScore({
        asset_score_id: asset_score_id,
      } as Partial<MovieScoreAsset>);

      if (!res.error) {
        row.values.asset_score_id = null;
        updateMyData(
          row.index,
          column.id,
          null,
          "asset_score_id",
          asset_score_id
        );
      } else {
        setValue(initialValue);
        setMessage();
      }
    } else if (e.target.value) {
      if (newScore) {
        const res: any = await addScore({
          imdb_id: imdbId,
          score: score,
        } as Partial<MovieScoreAsset>);
        if (!res.error) {
          updateMyData(
            row.index,
            column.id,
            score,
            "asset_score_id",
            res.asset_score_id
          );
        } else {
          setValue(initialValue);
          setMessage();
        }
      } else {
        const res: any = await updateScore({
          asset_score_id: asset_score_id,
          score: score,
        } as Partial<MovieScoreAsset>);

        if (!res.error) {
          updateMyData(
            row.index,
            column.id,
            score,
            "asset_score_id",
            asset_score_id
          );
        } else {
          setValue(initialValue);
          setMessage();
        }
      }
    }
  };

  return (
    <DropdownScoreControl>
      <InputLabel variant="standard" 
      id="scoreSelect"
      htmlFor="uncontrolled-native">
        {value ? "" : "N/R"}
      </InputLabel>
      <ScorDropDownTable variant="standard" labelId="scoreSelect" value={value ? value : ""} onChange={onSelectChange}>
        {[
          { score: "N/R" },
          { score: 100 },
          { score: 200 },
          { score: 300 },
          { score: 400 },
          { score: 500 },
        ].map((i, index) => {
          return (
            <Item key={index} value={i.score}>
              {i.score}
            </Item>
          );
        })}
      </ScorDropDownTable>
    </DropdownScoreControl>
  );
};
