import styled from 'styled-components/macro';
import CustomTable from './tableComponents/CustomTable';
import { DashboardWrapper } from './AdminDashboard.styles';

export const ChannelPageWrapper = styled(DashboardWrapper)`

`

export const ChannelTableWrapper = styled(CustomTable)`

    tbody {
        td {
            padding: 10px;
        }
    }

`