import { useEffect, useRef, useState } from "react";

import Search from "../../assets/images/mobile-search.svg";
import Menu from "../../assets/images/menu.svg";
import useHandleChange from "../../hooks/ProviderTabComponentHooks";
import { apiProviderTypeHome } from "../../app/app.types";
import "./MobileProviderMenu.scss";

interface MobileProviderMenuProps {
  providerTab: number;
}

const MobileProviderMenu: React.FC<MobileProviderMenuProps> = ({
  providerTab,
}) => {
  const {handleChange} = useHandleChange();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement | null>(null);
  const HamburgerIcon = () => (
    <img src={Menu} alt="Menu" style={{ width: 24, height: 24 }} />
  );
  const SearchIcon = () => (
    <img
      src={Search}
      alt="Search"
      style={{
        width: 24,
        height: 24,
        filter: providerTab === 5 ? " brightness(0) invert(1)" : "",
      }}
    />
  );

  const DropdownMenu = ({ isOpen, providers, selectedProvider }: any) => {
    if (!isOpen) return null;

    return (
      <div ref={menuRef} className="provider-dropdown">
        {providers.map((provider: string, index: number) => (
          <div
            className="mobile-provider"
            key={index}
            onClick={() => {
              setIsMenuOpen(false);
              handleChange(index);
            }}
          >
            {provider}
          </div>
        ))}
      </div>
    );
  };

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (isMenuOpen && menuRef.current && !menuRef.current.contains(event.target)) {
        setIsMenuOpen(false);
      }
    }
  
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isMenuOpen, menuRef]);

  return (
    <div id="mobile-tabs">
      <div className="selected-wrapper">
        <div className="mobile-selected">
          {apiProviderTypeHome[providerTab]}
        </div>
      </div>
      <DropdownMenu
        providers={["Amazon", "Apple TV+", "Disney+", "Max", "Netflix"]}
        onSelect={(index: number) => {
          setIsMenuOpen(false);
          handleChange(index);
        }}
        selectedProvider={providerTab}
      />
      <div className="icon" onClick={() => setIsMenuOpen(true)}>
        <HamburgerIcon />
      </div>
      <DropdownMenu
        ref={menuRef}
        isOpen={isMenuOpen}
        providers={["Amazon", "Apple TV+", "Disney+", "Max", "Netflix"]}
        onMouseEnter={() => setIsMenuOpen(true)}
        onMouseLeave={() => setIsMenuOpen(false)}
      ></DropdownMenu>
      <div className="icon" onClick={() => handleChange(5)}>
        <SearchIcon />
      </div>
    </div>
  );
};

export default MobileProviderMenu;
