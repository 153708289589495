import {
  useIsFetching,
  useIsMutating,
  useMutation,
  useQuery,
} from "@tanstack/react-query";
import { ExtractFnReturnType } from "../../lib/react-query";
import {
  fetchFilteringStatus,
  // fetchSubscriptionOptions,
  getMe,
} from "./userApi";
import {
  FetchUserQueryUtilEnum,
  MeMutations,
  SubscriptionOptions,
  User,
} from "./userTypes";
import { useEffect, useMemo, useState } from "react";
import { userLoadingSignal, userLoggedInSignal, userMessageSignal } from "./userSignals";

export const useUser = () => {
  const [userLoading, setUserLoading] = useState(false);

  const isMutatingSubscription = useIsMutating({
    mutationKey: [MeMutations.UPDATE_SUBSCRIPTION],
  });
  const isMutatingPayment = useIsMutating({
    mutationKey: [MeMutations.PAYMENT_METHOD],
  });
  const isMutatingMe = useIsMutating({
    mutationKey: [MeMutations.UPDATE_ME],
  });


  const isFetchingUser = useIsFetching({
    queryKey: ["me"],
  });
  const isLoadingUser = useMemo(
    () =>
      isMutatingSubscription +
      isMutatingPayment +
      isMutatingMe +
      isFetchingUser,
    [
      isMutatingPayment,
      isMutatingSubscription,
      isMutatingMe,
      isFetchingUser,
    ]
  );

  type GetMeQueryFnType = typeof getMe;

  const useMe = () => {
    return useQuery<ExtractFnReturnType<GetMeQueryFnType>>({
      queryKey: ["me"],
      queryFn: async () => await getMe(),
      onSuccess: async (data: User) => {
        userLoggedInSignal.value = true
        userLoadingSignal.value = false
        return data;
      },
      onError: async (error: any) => {
        console.log("error fetching user", error);
      },
      enabled: false,
    });
  };

  // type FetchSubscriptionOptionsQueryFnType = typeof fetchSubscriptionOptions;

  // const useFetchSubscriptionOptions = (promo_code: string) => {
  //   let body: any = {};
  //   if (promo_code) {
  //     body.promo_code = promo_code;
  //   }

  //   return useQuery<ExtractFnReturnType<FetchSubscriptionOptionsQueryFnType>>({
  //     queryKey: ["subscriptionOptions", promo_code],
  //     queryFn: async () => await fetchSubscriptionOptions(body),
  //     onSuccess: async (data: SubscriptionOptions[]) => {
  //       return data;
  //     },
  //     onError: async (error: any) => {
  //       console.log("error fetching user", error);
  //     },
  //     enabled: false,
  //   });
  // };


  type FetchUserUtilQueryFnType<T> = (...args: any[]) => Promise<T>;

interface FetchUserUtilQueryOptions<T> {
  queryKey: FetchUserQueryUtilEnum;
  queryFn: FetchUserUtilQueryFnType<T>;
  initialParams?: any[];
}

function useFetchUserUtil<T>({
  queryKey,
  queryFn,
  initialParams = [],
}: FetchUserUtilQueryOptions<T>) {
  return useQuery<T>([queryKey, ...initialParams], 
    async () => await queryFn(...initialParams), 
    {
      onSuccess: async (data: T) => {
   
      },
      onError: async (error: any) => {
        console.log("error fetching user util data", error);
      },
      enabled: false,
      refetchInterval: 5000
    }
  );
}

  const { refetch: fetchMe } = useMe();

  type MeMutationResult = any;
  const useMeMutation = <T extends MeMutationResult>(
    mutationKey: [MeMutations],
    mutationFn: (params: any) => Promise<T>
  ) => {
    return useMutation({
      mutationKey: mutationKey,
      mutationFn: mutationFn,
      onSuccess: () => {
        fetchMe();
      },
      onError: async (error: any) => {
       return userMessageSignal.value = { message: error.message, color: "red" }
      },
    });
  };

  const { refetch: fetchUserFilteringStatus } = useFetchUserUtil({queryKey: FetchUserQueryUtilEnum.FETCH_FILTERING_STATUS, queryFn: fetchFilteringStatus})

  useEffect(() => {
    const newLoadingState = isLoadingUser > 0;

    setUserLoading((prev) => {
      if (prev !== newLoadingState) {
        return newLoadingState;
      } else return prev;
    });
  }, [isLoadingUser]);

  return {
    useMe,
    useMeMutation,
    // useFetchSubscriptionOptions,
    userLoading,
    useFetchUserUtil,
    fetchUserFilteringStatus
  };
};
