import styled from 'styled-components/macro';
import TextInput from '../../features/components/formComponents/TextInput';
import { AppBar, Tab, Tabs } from '@mui/material';

export const PageWrapper = styled.div`
display: flex;
flex-direction: column;
align-items: center;
padding: 100px 20px 20px 20px;
min-height: calc(100vh - 70px);
header {
  padding-bottom: 5px;
  padding-top: 20px;
}
`
export const FormContainer = styled.form`
max-width: 419px;
width: 100%;
position: relative;
button {
  margin-bottom: 20px;
}
`

export const Header = styled.h1`
margin: 0px;
    font-size: 34px;
    color: #fff;
    font-weight: 900;
`

export const StepIndicator = styled.p`
    font-size: clamp(10px, 4vw, 16px);
    color: white;
`

export const PromoIndicator = styled.div`
width: 80px;
height: 30px;
background: #66CC99 0% 0% no-repeat padding-box;
border-radius: 3px;
opacity: 1;
position: absolute;
top: -15px;
right: -15px;
display: flex;
justify-content: center;
align-items: center;
font-size: 12px;
color: #000000;
font-weight: 900;
z-index: 1;
position: absolute;
`

export const TabBar = styled(AppBar)`
&&{
  padding: 30px 0;
  background: transparent;
  box-shadow: none;
}
`;

export const TabHolder = styled(Tabs)`
&&{
  display: flex;
  justify-content: center;
  margin: auto;
  .MuiTabs-indicator {
    background-color: #ffcc66;
    height: 4px;
  }
}
`;

export const Provider = styled(Tab)`
&&{
  text-transform: none;
  width: fit-content;
  margin: 0 5px;
  padding: 0;
  font-size: 18px;
  min-width: 70px;
  .MuiTab-wrapper {
    padding: 0;
    font-family: 'Avenir', 'Avenir-Condensed';
    font-weight: 900;
    min-width: 200px;
    display: flex;
    flex-direction: row;
    img {
      width: 25px;
      margin-right: 10px;
    }
  }
}
`;

export const Icon =styled.img``

export const MinorText = styled.div`
font-weight: 16px;
color: white;
`
export const MinorTextLink = styled.span`
font-weight: 900;
font-size: 16px;
:hover {
  cursor: pointer;
}
`

export const BackLink = styled.div`
color: #999999;
&:hover{
    cursor: pointer;
}
margin-bottom: 20px;
`

export const SocialImageText = styled.div`
display: flex;
align-items: center;
color: #fff;
&:hover {
  cursor: pointer;
}
margin-bottom: 50px;
`

export const SocialImage = styled.img`
width: 30px;
height: auto;
margin-right: 20px;
`

export const SocialWrapper = styled.div`
display: flex;
flex-direction: column;
`

export const OrSocial = styled.p`
margin-top: 40px;
margin-bottom: 50px;
font-size: 36px;
color: #fff;
text-align: left;
font-weight: 900;
`

export const ErrorText = styled.span`
    font-size: 1.2rem;
    color: red;
    float: right;
    margin-right: 15px;
    margin-top: 5px;
`

export const PageOverlay = styled.div`
position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  background: rgba(0, 0, 0, .3);
  z-index: 9;
`
export const RecapWrap = styled.div`
  margin-top: 50px;
`

export const PaymentLoader = styled.div`
min-height: 465px;
width: 100%;
max-height: fit-content;
`
export const PhoneInput = styled(TextInput)`
padding-left: 20px;
padding-right: 20px;
`