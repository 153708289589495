import React from "react";
import { Close } from "@mui/icons-material";
import { StyledSnackBar, SnackContent } from "./SnackBar.styles";
import { userMessageSignal } from "../user/userSignals";
import { Button } from "@mui/material";

export default function AppSnackBar() {
  const handleClose = (event: any, reason?: string) => {
    event?.stopPropagation();
    userMessageSignal.value = { message: "", color: "transparent" }
  };

  const action = (
    <React.Fragment>
      <Button
        size="small"
        aria-label="close"
        onClick={handleClose}
      >
        <Close fontSize="small" />
      </Button>
    </React.Fragment>
  );

  return (
    <>
      <StyledSnackBar
        id="app-snack"
        open={userMessageSignal.value.message ? true : false}
        autoHideDuration={6000}
        onClose={handleClose}
        action={action}
      >
        <SnackContent
          style={{ backgroundColor: userMessageSignal.value.color, color: "white" }}
          message={userMessageSignal.value.message}
        />
      </StyledSnackBar>
    </>
  );
}
