import { NumInput } from '../CustomCell.styles';

interface INumberInputCell {
  value: string | number;
  onInputChange: Function;
  onInputBlur: Function;
}

export const NumberInputCell: React.FC<INumberInputCell> = ({
  value,
  onInputChange,
  onInputBlur,
}) => <NumInput variant="standard" value={value} onChange={onInputChange} onBlur={onInputBlur} />;
