import {
  FocusContext,
  useFocusable,
} from "@noriginmedia/norigin-spatial-navigation";
import React, { useEffect, useRef, useState } from "react";
import { Provider, TabHolder } from "./ProviderTabComponent.styles";
import { filteringDownProvider } from "../../admin/utils";
import { useHandleChange } from "../../hooks/ProviderTabComponentHooks";
import { queryClient } from "../../lib/react-query";
import { Platform } from "../adminFeatures/tables/tableTypes";
import { FetchUserQueryUtilEnum } from "../user/userTypes";
import { tabProviderMap } from "../../app/app.types";
import { flixProviderSignal } from "../flix/useFlix";
import {
  returnIndexFromProvider,
} from "../flix/flixTypes";

import MobileProviderMenu from "./MobileProviderMenu";
import { userLoggedInSignal } from "../user/userSignals";

interface ProviderTabComponentProps {
  loggedIn: boolean;
  providerTab: number;
  mounted: boolean;
  setMounted: React.Dispatch<React.SetStateAction<boolean>>;
}
let isScrolling = false;
interface ProviderLinkProps {
  loggedIn: boolean;
  label: string;
  allyProps: Function;
  index: number;
  providerTab: number;
  hasBeenFocusedRef: React.MutableRefObject<boolean>;
  mounted: boolean;
  setMounted: React.Dispatch<React.SetStateAction<boolean>>;
}
export const ProviderLinks: React.FC<ProviderLinkProps> = React.memo(
  ({ loggedIn, label, index, mounted, setMounted }) => {
    const { handleChange } = useHandleChange();
    const provider = flixProviderSignal.value;
    const { ref, setFocus, focusKey } = useFocusable({
      onFocus: () => {
        if (!isScrolling) {
          return handleChange(index);
        }
      },
      focusKey: `provider-tab-${index}`,
    });
    const isFocused = tabProviderMap[index] === provider;

    useEffect(() => {
      const focusKeys = [
        "provider-tab-0",
        "provider-tab-1",
        "provider-tab-2",
        "provider-tab-3",
        "provider-tab-4",
        "provider-tab-5",
      ];

      const providerTab = returnIndexFromProvider(flixProviderSignal.value);

      if (mounted) {
        setFocus(focusKeys[providerTab]);
      }
      return () => {
        setMounted(false);
      };
    }, [mounted]);

    return (
      <FocusContext.Provider value={focusKey}>
        <Provider
          data-focuskey={focusKey}
          focused={isFocused ? "focused" : ""}
          onClick={() => handleChange(index)}
          selectionFollowsFocus={true}
          ref={ref}
          loggedin={loggedIn.toString()}
          disableRipple
          label={label}
          isSearch={label === "Search"}
        />
      </FocusContext.Provider>
    );
  }
);

const ProviderTabComponent: React.FC<ProviderTabComponentProps> = React.memo(
  ({ loggedIn, providerTab, mounted, setMounted }) => {
    const provider = flixProviderSignal.value;
    const filteringDown = queryClient.getQueryData<Platform[]>([
      FetchUserQueryUtilEnum.FETCH_FILTERING_STATUS,
    ]);
    const hasBeenFocused = useRef(false);
    const { ref, focusKey } = useFocusable({
      focusable: true,
      saveLastFocusedChild: true,
      trackChildren: true,
      autoRestoreFocus: true,
      focusKey: "Tab-Menu",
    });
    const [isMobileView, setIsMobileView] = useState(
      userLoggedInSignal ? window.innerWidth < 600 : window.innerWidth < 1150
    );

    function a11yProps(index: number) {
      return {
        id: `provider-tab-${index}`,
        "aria-controls": `provider-tabpanel-${index}`,
      };
    }
    useEffect(() => {
      const handleResize = () => {
        setIsMobileView(
          userLoggedInSignal.value
            ? window.innerWidth < 600
            : window.innerWidth < 1150
        );
      };
      const handleScroll = () => {
        isScrolling = true;
      };
      window.addEventListener("scroll", handleScroll);
      window.addEventListener("resize", handleResize);
      return () => {
        window.removeEventListener("scroll", handleScroll);
        window.removeEventListener("resize", handleResize);
      };
    }, [setIsMobileView]);

    return (
      <FocusContext.Provider value={focusKey}>
        {isMobileView ? (
          <MobileProviderMenu providerTab={providerTab} />
        ) : (
          <TabHolder
            id="tab-holder"
            messaging={filteringDownProvider(
              filteringDown,
              provider!
            )?.web_banner.toString()}
            value={providerTab}
            onChange={useHandleChange}
            loggedin={loggedIn.toString()}
            ref={ref}
          >
            {["Amazon", "Apple TV+", "Disney+", "Max", "Netflix", "Search"].map(
              (name, i) => (
                <ProviderLinks
                  mounted={mounted}
                  setMounted={setMounted}
                  hasBeenFocusedRef={hasBeenFocused}
                  key={i}
                  providerTab={providerTab}
                  loggedIn={loggedIn}
                  label={name}
                  allyProps={a11yProps}
                  index={i}
                />
              )
            )}
          </TabHolder>
        )}
      </FocusContext.Provider>
    );
  }
);

export default ProviderTabComponent;
