export interface CustomerSearchResult {
    created_at: string;
    email: string;
    name: string;
    phone: string;
    user_id: string;
  }

  export interface CustomerUser {
    user: User;
    stripe: Stripe2;
  }
  
  interface Stripe2 {
    id: string;
    object: string;
    address: Address;
    balance: number;
    created: number;
    currency: string;
    default_source: Defaultsource;
    delinquent: boolean;
    description?: any;
    discount?: any;
    email: string;
    invoice_prefix: string;
    invoice_settings: Invoicesettings;
    livemode: boolean;
    metadata: Metadata2;
    name: string;
    next_invoice_sequence: number;
    phone: string;
    preferred_locales: any[];
    shipping: Shipping;
    subscriptions: Subscriptions;
    tax_exempt: string;
    test_clock?: any;
  }
  
  interface Subscriptions {
    object: string;
    data: Datum2[];
    has_more: boolean;
    total_count: number;
    url: string;
  }
  
  interface Datum2 {
    id: string;
    object: string;
    application?: any;
    application_fee_percent?: any;
    automatic_tax: Automatictax;
    billing_cycle_anchor: number;
    billing_thresholds?: any;
    cancel_at?: any;
    cancel_at_period_end: boolean;
    canceled_at?: any;
    cancellation_details: Cancellationdetails;
    collection_method: string;
    created: number;
    currency: string;
    current_period_end: number;
    current_period_start: number;
    customer: string;
    days_until_due?: any;
    default_payment_method?: any;
    default_source?: any;
    default_tax_rates: any[];
    description?: any;
    discount: Discount;
    ended_at?: any;
    items: Items;
    latest_invoice: string;
    livemode: boolean;
    metadata: Metadata;
    next_pending_invoice_item_invoice?: any;
    on_behalf_of?: any;
    pause_collection?: any;
    payment_settings: Paymentsettings;
    pending_invoice_item_interval?: any;
    pending_setup_intent: string;
    pending_update?: any;
    plan: Plan;
    quantity: number;
    schedule?: any;
    start_date: number;
    status: string;
    test_clock?: any;
    transfer_data?: any;
    trial_end?: any;
    trial_settings: Trialsettings;
    trial_start?: any;
  }
  
  interface Trialsettings {
    end_behavior: Endbehavior;
  }
  
  interface Endbehavior {
    missing_payment_method: string;
  }
  
  interface Paymentsettings {
    payment_method_options?: any;
    payment_method_types?: any;
    save_default_payment_method: string;
  }
  
  interface Items {
    object: string;
    data: Datum[];
    has_more: boolean;
    total_count: number;
    url: string;
  }
  
  interface Datum {
    id: string;
    object: string;
    billing_thresholds?: any;
    created: number;
    metadata: Metadata;
    plan: Plan;
    price: Price;
    quantity: number;
    subscription: string;
    tax_rates: any[];
  }
  
  interface Price {
    id: string;
    object: string;
    active: boolean;
    billing_scheme: string;
    created: number;
    currency: string;
    custom_unit_amount?: any;
    livemode: boolean;
    lookup_key?: any;
    metadata: Metadata;
    nickname: string;
    product: string;
    recurring: Recurring;
    tax_behavior: string;
    tiers_mode?: any;
    transform_quantity?: any;
    type: string;
    unit_amount: number;
    unit_amount_decimal: string;
  }
  
  interface Recurring {
    aggregate_usage?: any;
    interval: string;
    interval_count: number;
    trial_period_days: number;
    usage_type: string;
  }
  
  interface Plan {
    id: string;
    object: string;
    active: boolean;
    aggregate_usage?: any;
    amount: number;
    amount_decimal: string;
    billing_scheme: string;
    created: number;
    currency: string;
    interval: string;
    interval_count: number;
    livemode: boolean;
    metadata: Metadata;
    nickname: string;
    product: string;
    tiers_mode?: any;
    transform_usage?: any;
    trial_period_days: number;
    usage_type: string;
  }
  
  interface Discount {
    id: string;
    object: string;
    checkout_session?: any;
    coupon: Coupon;
    customer: string;
    end?: any;
    invoice?: any;
    invoice_item?: any;
    promotion_code?: any;
    start: number;
    subscription: string;
  }
  
  interface Coupon {
    id: string;
    object: string;
    amount_off?: any;
    created: number;
    currency?: any;
    duration: string;
    duration_in_months?: any;
    livemode: boolean;
    max_redemptions?: any;
    metadata: Metadata;
    name: string;
    percent_off: number;
    redeem_by?: any;
    times_redeemed: number;
    valid: boolean;
  }
  
  interface Cancellationdetails {
    comment?: any;
    feedback?: any;
    reason?: any;
  }
  
  interface Automatictax {
    enabled: boolean;
  }
  
  interface Shipping {
    address: Address;
    name: string;
    phone: string;
  }
  
  interface Metadata2 {
    Auth0: string;
  }
  
  interface Invoicesettings {
    custom_fields?: any;
    default_payment_method?: any;
    footer?: any;
    rendering_options?: any;
  }
  
  interface Defaultsource {
    id: string;
    object: string;
    address_city?: any;
    address_country?: any;
    address_line1?: any;
    address_line1_check?: any;
    address_line2?: any;
    address_state?: any;
    address_zip?: any;
    address_zip_check?: any;
    brand: string;
    country: string;
    customer: string;
    cvc_check: string;
    dynamic_last4?: any;
    exp_month: number;
    exp_year: number;
    fingerprint: string;
    funding: string;
    last4: string;
    metadata: Metadata;
    name?: any;
    tokenization_method?: any;
    wallet?: any;
  }
  
  interface Metadata {
  }
  
  interface Address {
    city: string;
    country: string;
    line1: string;
    line2: string;
    postal_code: string;
    state: string;
  }
  
  interface User {
    created_at: string;
    email: string;
    email_verified: boolean;
    family_name: string;
    given_name: string;
    identities: Identity[];
    locale: string;
    name: string;
    nickname: string;
    picture: string;
    updated_at: string;
    user_id: string;
    user_metadata: Usermetadata;
    app_metadata: Appmetadata;
    last_ip: string;
    last_login: string;
    logins_count: number;
  }
  
  interface Appmetadata {
    stripe: Stripe;
    salesforce: Salesforce;
  }
  
  interface Salesforce {
    accountId: number;
  }
  
  interface Stripe {
    id: string;
    lastUpdated: string;
    status: string;
  }
  
  interface Usermetadata {
    contact: Contact;
  }
  
  interface Contact {
    firstName: string;
    lastName: string;
    address?: any;
    city?: any;
    state?: any;
    zipCode: string;
    phone: string;
    email: string;
  }
  
  interface Identity {
    provider: string;
    access_token: string;
    expires_in: number;
    user_id: string;
    connection: string;
    isSocial: boolean;
  }


  export enum FetchAdminDataQueryKeysEnum {
    FETCH_CLASSIFICATIONS = 'fetchClassifications',
    FETCH_ADMIN_LINKS = 'fetchAdminLinks'
  }

  export interface IClock {
    id: string;
    object: string;
    created: number;
    deletes_after: number;
    frozen_time: number;
    livemode: boolean;
    name: string;
    status: string;
  }

  export type StatsData = {
    customers: {
        emoji: string;
        header: string;
        counts: {
            newComments: number;
            reportedUnresolvedComments: number;
        };
    };
    episodes: {
        emoji: string;
        header: string;
        counts: {
            missingCreditStartTime: number;
            missingDisplayName: number;
            missingDuration: number;
            missingImdbIds: number;
            releasedStats: Array<{
                platformId: number;
                releasedCount: number;
                runtimeSeconds: number;
            }>;
        };
    };
    movies: {
        emoji: string;
        header: string;
        counts: {
            missingCreditStartTime: number;
            missingDisplayName: number;
            missingDuration: number;
            missingImdbId: number;
            missingPoster: number;
            releasedStats: Array<{
                platformId: number;
                releasedCount: number;
                runtimeSeconds: number;
            }>;
        };
    };
};

