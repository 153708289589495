import TagManager from 'react-gtm-module'
import { userUUIDSignal } from '../features/user/userSignals'

export const useTagManager = () => {

    const dataLayerPush = (body: any) => {
        TagManager.dataLayer({
            dataLayer: { cpId: userUUIDSignal.value, ...body}
        })
    }

    return {dataLayerPush}
}