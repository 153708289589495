import { Tooltip, tooltipClasses } from '@mui/material';
import styled from 'styled-components/macro';


export const SearchImageWrapper = styled.div`
  width: ${(props) => props.expanded && '15%'};
  aspect-ratio: 2/3;
  margin: 10px 7px 0;
  margin-bottom: 20px;
  backface-visibility: hidden;
  border-radius: 6px;
  display: block;
  transform: scale(1, 1) translateZ(0px);
  transition-duration: 100ms;
  transition-property: transform, box-shadow;
  transition-timing-function: ease-out;
  overflow: visible;
  height: ${(props) =>
    props.isMobile
      ? 'clamp(145px, 55vw, 368px);'
      : 'clamp(145px, 23vw, 400px);'};
  .provider-overlay {
    display: none;
  }
  &:hover {
    transform: scale(1.009, 1.009) translateZ(0px) translate3d(0px, 0px, 0px);
    transition-duration: 100ms;
    transition-property: transform, box-shadow;
    transition-timing-function: ease-out;
    border-radius: 7px;
    .provider-overlay {
      display: flex;
      z-index: 99999;
      position: absolute;
      height: 100%;
      width: 100%;
      background-color: rgba(0, 0, 0, 0.8);
      border: 4px solid rgba(255, 255, 255, 0.8);
      border-radius: 8px;
      justify-content: center;
      align-items: center;
      color: white;
      flex-direction: column;
      transition: all 1s ease;
    }
    ::after {
      inset: 0px;
      content: '';
      position: absolute;
      transition: border 300ms ease-out 0s;
      border-radius: 3px;
    }
  }
`;

export const ProviderOverlay = styled.div``;

export const Stream = styled.span`
  position: absolute;
  top: 20px;
  color: lightGray;
  font-weight: 100;
`;

export const PlatformLink = styled.div`
  font-size: clamp(12px, 2vw, 25px);
  font-weight: 900;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: ${props => props.isMobile ? '20px' : '60px'};
  flex-direction: ${props => props.isMobile ? 'row' : 'column'};;
`;

export const BorderNameWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
  justify-content: center;
  height: ${(props) =>
    props.isMobile ? 'fit-content !important' : '100%; !important'};
  margin-bottom: 0;
  display: ${(props) => (props.clickedOnMobile ? 'none' : 'flex')};
  .underline {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s 0.5s, opacity 0.5s linear;
  }
  &:hover {
    cursor: pointer;
    .underline {
      border-bottom: 6px solid #ffcc66;
      position: absolute;
      bottom: 5px;
      width: 100%;
      height: 6px;
      visibility: visible;
      opacity: 1;
      transition: opacity 0.5s linear;
    }
  }
`;

export const ImageProviderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${(props) => (props.isMobile ? 'flex-end' : 'center')};
  justify-content: center;
  height: 100%;
  width: 100%;



  &.two-platforms {
    div:nth-child(1) {
      div {
        position: absolute;
        bottom: 5px;
      }
    }
    div:nth-child(2) {
      div {
        position: absolute;
        top: 5px;
      }
    }
  }
  &.three-platforms {
    div:nth-child(1) {
      height: 35%;
      div {
        height: 60px;
        position: absolute;
        bottom: 5px;
      }
    }
    div:nth-child(2) {
      height: 25%;
      display: flex;
      justify-content: center;
      div {
        height: 60px;
      }
    }
    div:nth-child(3) {
      height: 35%;
      div {
        height: 60px;
        position: absolute;
        top: 5px;
      }
    }
  }
  &.four-platforms {
    div:nth-child(1) {
      height: 30%;
      div {
        height: 60px;
        position: absolute;
        bottom: -10px;
      }
    }
    div:nth-child(2) {
      height: 20%;
      div {
        height: 60px;
        position: absolute;
        bottom: -10px;
      }
    }
    div:nth-child(3) {
      height: 20%;
      div {
        height: 60px;
        position: absolute;
        bottom: -10px;
      }
    }
    div:nth-child(4) {
      height: 30%;
      div {
        height: 60px;
        position: absolute;
        top: 20px;
      }
    }
  }
`;

export const Checkmark = styled.img`
  width: 15px;
  margin-left: 8px;
`;

export const MessageText = styled.div`
position: absolute ;
height: 22px;
text-align: center;
letter-spacing: 0px;
color: #000000;
height: 102px;
background: #FFFFFF 0% 0% no-repeat padding-box;
display: flex ;
justify-content: center;
align-items: center;
font-size: clamp(12px, 2.5vw, 22px);
position: fixed;
    right: 0;
    width: 100%;
    z-index: 9;
`

const SearchImageTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#fff',
    padding: '15px 20px',
    fontSize: '16px',
    borderRadius: '20px',
    maxWidth: '250px',
    lineHeight: 1.5,
    color: '#000',
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: '#fff', // Styling the arrow
  },
});

export const MessageTooltip = (props) => {
  if (!props.displayMessage) return <>{props.children}</>;
  return <SearchImageTooltip id="fm-tt" arrow {...props} />;
};