import styled, { keyframes } from 'styled-components/macro';
import { ImageWrapper } from './LazyImage.styles';
import { Colors} from '../../theme'

export const loadingAnimation = keyframes`

  0% {
    background-color: rgba(78,78,101, .1);
  }
  50% {
    background-color: rgba(240,240,240, .1);
  }
  100% {
    background-color: rgba(78,78,101, .1);
  }
`;

export const Placeholder = styled.div`
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(240, 240, 240, 0.1);
  animation: ${loadingAnimation} 1.5s infinite;
  cursor: pointer;
  display: block;
  transform: scale(1, 1) translateZ(0px);
  height: 100%;
  aspect-ratio: 2/3;
`;



export const StyledImage = styled.img`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  object-fit: cover;
`;

export const NoUrl = styled(ImageWrapper)`
  background-color: rgba(240, 240, 240, 0.1);
  margin: auto !important;
  width: unset;
  font-size: min(max(20px, 2vw), 28px);
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  span {
        color: ${Colors.colorBlue};
        font-size: 20px;
        &:hover {
            cursor: pointer;
        }
    }
  div {
    font-family: 'Avenir-Condensed';
    font-size: min(max(20px,2vw),28px);
    color: #fff;
    font-weight: bold;
    margin-bottom: 10px;
  }
`;
export const FailedUrl = styled(NoUrl)`
  display: none;
`;

export const FailedImgText = styled.div`
  text-align: center;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;


