import styled from "styled-components/macro";

export const KeyWarpper = styled.div`
  display: flex;
  flex-direction: row;
  background-color: #15202b !important;
  width: fit-content;
  padding: 10px;
  border-radius: 8px;
  height: 25px;
  margin-top: 10px;
`;
export const KeysWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    color: white;
    margin: 0 10px;
    font-size: 12px;
  }
`;
export const ColorPallet = styled.div`
  width: 30px;
  height: 20px;
  border-radius: 8px;
  background-color: ${(props) => props.color};
`;
