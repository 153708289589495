import { TextField } from '@mui/material';
import LoadingOverlay from 'react-loading-overlay-ts';
import styled from 'styled-components/macro';

//breakpoints
const size = {
  sm: '400px',
  md: '775px',
  lg: '1080px',
  xl: '1440px',
  xxl: '1620px',
};

export const breakpoint = {
  sm: `(min-width: ${size.sm})`,
  md: `(min-width: ${size.md})`,
  lg: `(min-width: ${size.lg})`,
  xl: `(min-width: ${size.xl})`,
  xxl: `(min-width: ${size.xxl})`,
};

export const Colors = {
  colorBlue: '#66ccff',
  colorOrange: '#FFCC66',
  backgroundColor: 'linear-gradient( 0deg,rgba(49,50,63,1) 0%,rgba(49,50,63,1) 70%,rgba(0,0,0,1) 100% );'
}

export const Header = styled.h1`
  font-size: 34px;
  font-weight: 900;
  margin-left: 15px;
  color: white;
`;

export const AppLoading = styled(LoadingOverlay)`
  ._loading_overlay_overlay {
    position: fixed;
    top: 0;
    height: 100%;
    min-height: 100vh;
  }
  ._loading_overlay_content {
    display: flex;
    justify-content: center;
  }
`;

export const ContentLoading = styled(LoadingOverlay)`
    ._loading_overlay_overlay {
      position: relative;
      height: 0px !important;
      min-height: 0;
      background-color: transparent;
    }
    ._loading_overlay_content {
      display: flex;
      justify-content: center;
      align-items: center;
    }
`;

export const Spacer20 = styled.div`
  margin-top: 20px;
`


export const FlexDivColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`
export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`
export const FlexRowSB = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
`

export const DarkStyledInput = styled(TextField)`
  
  input {
      color: #8899a6;
      &::-webkit-calendar-picker-indicator {
        color: #c270c0;
      }
      &.Mui-disabled {
        -webkit-text-fill-color: rgba(136, 153, 166, .6)
      }
    }
  
    label.MuiInputLabel-root {
      color: #8899a6;
    }
  
    label.Mui-focused {
      color: #8899a6;
    }
  
    .MuiInput-underline:before {
      border-bottom: 1px solid #8899a6;
    }
  
    .MuiInput-underline:hover:not(.Mui-disabled):before {
      border-bottom: 2px solid #8899a6; // adjust as needed, default is 2px for Material-UI v4
    }
  
    .MuiInput-underline:after {
      border-bottom: 2px solid #8899a6; // this styles the line when the input is focused
    }
 
`