import { useFocusable } from '@noriginmedia/norigin-spatial-navigation';
import React, { useEffect } from 'react';
import {
  FieldError,
  FieldErrorsImpl,
  Merge,
  UseFormRegister,
} from 'react-hook-form';
import {
  InputContainer,
  Input,
  Exclamtion,
  ErrorMessage,
  Label,
  HelperText,
} from './TextInput.styles';
import { userLoadingSignal } from '../../user/userSignals';

interface TextInputProps {
  id?: string;
  name:
    | 'email'
    | 'password'
    | 'name'
    | 'payment'
    | 'exp'
    | 'security'
    | 'zip'
    | 'phone'
    | 'confirmEmail'
    | 'confirmPassword'
    | 'search';
  errorMessage?:
    | string
    | FieldError
    | Merge<FieldError, FieldErrorsImpl<any>>
    | undefined;
  register?: UseFormRegister<any>;
  label?: string | undefined;
  placeholder?: string;
  helperText?: string;
  min?: boolean;
  value?: string;
  type?: string;
  maxLength?: string;
  autoFocus?: any;
  onChange?: Function;
  renderIcon?: Function
  children?: any
}

const TextInput: React.FC<TextInputProps> = ({
  name,
  errorMessage,
  register,
  label,
  placeholder,
  helperText,
  min,
  value,
  type,
  onChange,
  maxLength,
  autoFocus,
  id,
  renderIcon,
  children,
  ...rest
}) => {
  const disabled = userLoadingSignal.value
  const { pause } = useFocusable();

  useEffect(() => {
    pause();
  }, [pause]);

  return (
    <InputContainer min={min}>
      <Label>{label}</Label>
      <Input
        id={id}
        autoFocus={autoFocus}
        filled={register?.name.valueOf()}
        type={type}
        maxLength={maxLength}
        disabled={disabled}
        placeholder={placeholder}
        onChange={onChange && onChange}
        autoComplete='off'
        {...(register && register(name))}
        {...rest}
        error={errorMessage ? 1 : 0}
        renderIcon={renderIcon}
      />
      {children}
      {errorMessage && <Exclamtion error={errorMessage ? 1 : 0} />}
      <ErrorMessage>{errorMessage}</ErrorMessage>
      <HelperText>{helperText}</HelperText>
    </InputContainer>
  );
};


export default TextInput