import Tooltip from "@mui/material/Tooltip";
import styled from "styled-components/macro";

export const CustomTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))`
    && .MuiTooltip-tooltip {
      font-size: 1rem;
    }
  `;
  
