import { EditOutlined } from '@mui/icons-material';
import { ButtonCell } from './ButtonCell';

interface IButtonCellComponenet {
  row: any;
  value: string;
  column: any;
}

export const ButtonCellComponenet: React.FC<IButtonCellComponenet> = ({
  row,
  value,
  column,
}) => {
  const text = column.id === 'manageShelf' ? <EditOutlined/> : 'See Details';
  if (column.id === 'total_votes') {
    return <ButtonCell text={value} row={row} columnId={column.id} />;
  }
  if (row.isGrouped) return <span />;
  return <ButtonCell text={text} row={row} columnId={column.id} />;
};
