import styled from 'styled-components';
import { ErrorOutline } from '@mui/icons-material';
export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: ${(props) => (props.min ? '200px' : 'unset')};
  img {
    width: 20px;
    position: absolute;
    right: 20px;
    top: 50%;
    cursor: pointer;
  }
`;
export const Input = styled.input`
  font-family: 'Avenir', 'Avenir-Condensed';
  color: white;
  height: 68px;
  font-size: 22px;
  font-weight: 500;
  border-radius: 0.3rem;
  padding-left: 10px;
  outline: none;
  background-color: transparent;
  border: 3px solid ${(props) => (props.error ? 'red' : '#999999')};
  &::placeholder {
    color: ${(props) => (props.error ? 'red' : '#999999')};
    opacity: 0.8;

  }
  &:focus {
    border: 3px solid #66ccff;
    background-color: white;
  }
  &:focus-within {
    color: #000000;
  }
  :first-child {
    margin-left: 10px;
  }
  :first-child {
    color:white;
  }

`;

export const ErrorMessage = styled.span`
  font-size: 1.2rem;
  color: red;
  text-align: right;
  margin-right: 15px;
  margin-top: 5px;
`;

export const Exclamtion = styled(ErrorOutline)`
  position: absolute;
  bottom: 3.3rem;
  right: 2rem;
  color: red;
  display: ${(props) => (props.error ? 'block' : 'none')};
  font-size: 2em;
`;

export const Label = styled.h1`
  margin: 15px 0 10px 0;
  font-size: 18px;
  color: #fff;
  font-weight: 100;
`;
export const HelperText = styled.p`
  font-size: 16px;
  color: white;
  position: absolute;
  bottom: -40px;
  left: 2px;
`;
export const MinFormWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
