import { providerMap } from '../../../app/app.types';
import { AssetDetail } from './assetTypes';

export const ConstructAssetDetail = (data: any[]): AssetDetail[] => {
  const assets = data.map((asset: any): AssetDetail => {
    return {
      clearplay: asset.cleraplay,
      comment: asset.comment,
      date: asset.modified_at,
      score: asset.score,
      userId: asset.user_id,
      email: asset.email,
      count: data.filter(x => x.user_id === asset.user_id).length,
      partner_identifier: asset.partner_identifier,
      platform_id: providerMap[asset.platform_id],
    };
  });
  assets.sort(function (a, b) {
    return (new Date(b.date) as any) - (new Date(a.date) as any);
  });
  const filtered = assets?.filter((value, index, self) => {
    return self.findIndex((v) => v.userId === value.userId) === index;
  });
  return filtered;
};
