import styled from 'styled-components/macro';

export const BackToAccountLink = styled.div`
color: #000000;
&:hover{
    cursor: pointer;
    color: #999999;
}
margin-top: 20px;
margin-bottom: 20px;
`