import React from 'react'
import { Redirect } from 'react-router'
import { AppRoutes } from '../app/routes'
import { Roles } from '../features/user/userTypes'
import { LocalStorage } from './localStorageService'
import { userRolesSignal } from '../features/user/userSignals'

interface RouteGaurdProps {
    allowedRoles: Roles[]
    render: Function
}

const RouteGuard:React.FC<RouteGaurdProps> = ({render, allowedRoles }) => {
  const loggedIn = localStorage.getItem(LocalStorage.CLEARPLAY_SESSION_TOKEN)
  if (loggedIn) {
    const roles = userRolesSignal.value as Roles[]
    const isAllowed = roles.filter((role: Roles) =>
      allowedRoles.includes(role)
    )

    if (isAllowed.length > 0) {
      return render({ userRole: roles })
    }

    return render()
  }
  return <Redirect to={AppRoutes.ENTER_EMAIL} />
}

export default RouteGuard
