import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { GoBack } from "../features/components/BackToAccount";
import { DashboardWrapper, Header } from "./AdminDashboard.styles";
import useSharedTableData from "./tableComponents/useSharedTableData.js";
import {
  AdminTableSelection,
  FetchTableDataUtilQueryKeysEnum,
  PlatformConfigObject,
} from "../features/adminFeatures/tables/tableTypes";
import { useTableData } from "../features/adminFeatures/tables/useTableData";
import { fetchPlatformConfigs } from "../features/adminFeatures/tables/table.api";
import { ChannelTableWrapper } from "./ChannelTable.styles";
import { selectedTable } from "../features/adminFeatures/useAdminData";

const ChannelTable: React.FC = () => {
  const location: any = useLocation();
  const { useFetchTableUtilDataQuery } = useTableData();
  const { channelColumns } = useSharedTableData();
  const { data: platformConfigs, refetch: refetchPlatformConfigs } =
    useFetchTableUtilDataQuery({
      queryKey: FetchTableDataUtilQueryKeysEnum.FETCH_PLATFORM_CONFIGS,
      queryFn: fetchPlatformConfigs,
    });

    const inputToKeyMapping: { [key: string]: keyof PlatformConfigObject } = {
      Amazon: "amazon.com",
      Netflix: "netflix.com",
      Disney: "disneyplus.com",
      MAX: "max.com",
      Aapple: "apple.com",
    };

    const channels = platformConfigs?.[inputToKeyMapping[window.location.hash.slice(1)]]?.channels



  useEffect(() => {
    selectedTable.value = AdminTableSelection.PLATFORM_CONTROLS
    refetchPlatformConfigs();
  }, [refetchPlatformConfigs]);

  if (!platformConfigs) return <></>;

  return (
    <DashboardWrapper>
      <Header>{`${location.hash.slice(1)}`}</Header>
      <GoBack />

      {channels ? (
        <ChannelTableWrapper
          hideSpacer={true}
          data={
            channels
          }
          columns={channelColumns.col}
          hidden={[]}
          selectedTable={AdminTableSelection.PLATFORM_CONTROLS}
          sortedBy={[]}
        />
      ) : (
        <h1>No Channels</h1>
      )}
    </DashboardWrapper>
  );
};

export default ChannelTable;
