import {
  useIsFetching,
  useQuery,
} from "@tanstack/react-query";
import { ExtractFnReturnType } from "../../../lib/react-query";
import { useEffect, useMemo, useState } from "react";
import {
  fetchClassifications,
  fetchPlatformConfigs,
  fetchShelfList,
  fetchTableData,
} from "./table.api";
import {
  AdminAsset,
  AdminTableSelection,
  CustomerComment,
  FetchTableDataUtilQueryKeysEnum,
  MovieScoreAsset,
  Platform,
  ShowsAsset,
} from "./tableTypes";
import { ShelvesTable } from "../assets/assetTypes";
import { FetchAdminDataQueryKeysEnum } from "../admin.types";
import { userMessageSignal } from "../../user/userSignals";

export const useTableData = () => {
  const [tableLoading, setIsTableLoaing] = useState(false);

  const fetchingMovieTableData = useIsFetching([
    AdminTableSelection.MOVIE_ASSET,
  ]);
  const fetchingShowTableData = useIsFetching([AdminTableSelection.SHOW_ASSET]);
  const fetchingCommentTableData = useIsFetching([
    AdminTableSelection.CUSTOMER_COMMENTS,
  ]);
  const fetchingPlatformTableData = useIsFetching([
    AdminTableSelection.PLATFORM_CONTROLS,
  ]);
  const fetchingShelvesTableData = useIsFetching([AdminTableSelection.SHELVES]);
  const fetchingScoresTableData = useIsFetching([
    AdminTableSelection.MOVIE_SCORE_ASSET,
  ]);
  const fetchingClassifications = useIsFetching([
    FetchAdminDataQueryKeysEnum.FETCH_CLASSIFICATIONS,
  ]);

  const isTableLoading = useMemo(
    () =>
      fetchingMovieTableData +
      fetchingClassifications +
      fetchingShowTableData +
      fetchingCommentTableData +
      fetchingPlatformTableData +
      fetchingShelvesTableData +
      fetchingScoresTableData,
    [
      fetchingMovieTableData,
      fetchingClassifications,
      fetchingScoresTableData,
      fetchingShelvesTableData,
      fetchingPlatformTableData,
      fetchingCommentTableData,
      fetchingShowTableData,
    ]
  );

  type FetchTableDataQueryFnType = typeof fetchTableData;

  type TableReturnResults =
    | MovieScoreAsset[]
    | ShowsAsset[]
    | Platform[]
    | AdminAsset[]
    | CustomerComment[]
    | ShelvesTable[];

  const useFetchTableDataQuery = (
    queryKey: AdminTableSelection,
    assetType: AdminTableSelection
  ) => {
    return useQuery<ExtractFnReturnType<FetchTableDataQueryFnType>>({
      queryKey: [queryKey, assetType],
      queryFn: async () => await fetchTableData({ assetType: assetType }),
      onSuccess: async (data: TableReturnResults) => {
        return data;
      },
      onError: (error: any) => {
        userMessageSignal.value = {
          message: error?.data?.message,
          color: "red",
        }
      },
      enabled: false,
      // retry: false
    });
  };

  type FetchTableDataUtilQueryFnType =
    | typeof fetchClassifications
    | typeof fetchShelfList
    | typeof fetchPlatformConfigs

  interface FetchTableDataUtilQueryOptions {
    queryKey: FetchTableDataUtilQueryKeysEnum;
    queryFn: (...args: any[]) => Promise<any>;
    initialParams?: any[]; // Define the initial parameters here
  }

  const useFetchTableUtilDataQuery = ({
    queryKey,
    queryFn,
    initialParams = [],
  }: FetchTableDataUtilQueryOptions) => {
    return useQuery<ExtractFnReturnType<FetchTableDataUtilQueryFnType>>({
      queryKey: [queryKey, ...initialParams], // Pass initial params to the query key for cache
      queryFn: async () => await queryFn(...initialParams), // Spread initial params here
      onSuccess: async (data: any) => {
        return data;
      },
      onError: async (error: any) => {
        console.log("error fetching data", error);
      },
      enabled: false,
    });
  };
  useEffect(() => {
    const newLoadingState = isTableLoading > 0;

    setIsTableLoaing((prev) => {
      if (prev !== newLoadingState) {
        return newLoadingState;
      } else return prev;
    });
  }, [isTableLoading]);

  return { useFetchTableDataQuery, useFetchTableUtilDataQuery, tableLoading };
};
