interface ICellGroup {
  column: any;
  row: any;
}
export const CellGroup: React.FC<ICellGroup> = ({ column, row }) => {
  if (column.id === 'series_asset_id') {
    return <span>{row.leafRows[0].original.series_asset_id}</span>;
  }
  if (column.id === 'series_imdb_id') {
    return <span>{row.leafRows[0].original.series_imdb_id}</span>;
  }
  // if (column.id === 'series_searchable') {
  //   return (
  //     <span>{row.leafRows[0].original.series_searchable ? 'Yes' : 'No'}</span>
  //   );
  // }
  if (column.id === 'series_name') {
    return <span>{row.values.series_name}</span>;
  }
  return null;
};
