import { Grid, Typography } from "@mui/material";
import { CustomTooltip } from "./HeaderTooltip.styled";
import { GridWrapper, KeyPoppupWrapper } from "./PlatformKey.styles";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  IconButton,
} from "@mui/material";
import { useState } from "react";
import { InfoOutlined } from "@mui/icons-material";

const headerKeys = [
  {
    icon: <span>🌐</span>,
    shortDescription: "Applies instantly",
    longDescription: "Change is reflected on website reload.",
  },
  {
    icon: <span>⌚</span>,
    shortDescription: "Applies with cache",
    longDescription:
      "Change will reflect when cache expires, which is usually less than 15 minutes.",
  },
];

const PlatformKey: React.FC = () => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <KeyPoppupWrapper>
      <CustomTooltip title="Click Here For Common Scenarios">
        <IconButton onClick={handleClickOpen}>
          <InfoOutlined />
        </IconButton>
      </CustomTooltip>
      <GridWrapper container spacing={2}>
        {headerKeys.map((key, index) => (
          <Grid item key={index}>
            <CustomTooltip title={key.longDescription}>
              <Typography variant="body2">
                {key.icon} {key.shortDescription}
              </Typography>
            </CustomTooltip>
          </Grid>
        ))}
      </GridWrapper>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Additional Information</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography variant="h6">Common Scenarios</Typography>
            <ul>
              <li>
                <strong>
                  Disable filtering and display the maintenance message for a
                  platform:
                </strong>
                <ul>
                  <li>
                    Enabled: <code>On</code>
                  </li>
                  <li>
                    Movies Active: <code>Off</code>
                  </li>
                  <li>
                    Shows Active: <code>Off</code>
                  </li>
                  <li>Maintenance Message: Provide the desired message</li>
                  <li>
                    Optionally, if you want to also include messaging on
                    website:
                    <ul>
                      <li>
                        Web Enabled: <code>Off</code>
                      </li>
                      <li>Maintenance Message: Provide the desired message</li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li>
                New platform (Coming soon) (No released assets)
                <ul>
                  <li>
                    Enabled: <code>Off</code>
                  </li>
                  <li>
                    Movies Active: <code>On</code>
                  </li>
                  <li>
                    Searchable: <code>On</code>
                  </li>
                </ul>
              </li>
            </ul>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </KeyPoppupWrapper>
  );
};

export default PlatformKey;
