export enum Roles {
    ADMIN = 'Admin',
    SUPPORT = 'Support',
    FILTER_SUPPORT = 'FilterSupport',
    CUSTOMER_SUPPORT = 'CustomerSupport',
    DEV_MODE = 'DevMode',
    TESTER = 'Tester'
}


export interface User {
    expMonth: number;
    expYear: number;
    last4: string;
    brand: string;
    firstName: string;
    lastName: string;
    email: string;
    result?: string;
    cancelAtPeriodEnd?: boolean;
    subscription: string;
    periodEndDate: string;
    loginType: string;
    user_id: string;
    canceled: boolean;
    nextBilling: string;
    substatus: string;
    created_at: string;
    phone: string;
    status: string;
    hasStripe: boolean
    stripeId: string
    amount: string
    interval: string
    discounted: boolean
    testMode?: boolean
    churnkeyHash: string
    activeCouponMessage: string
    isActive: boolean,
    trialAvailable: boolean
  }

export interface UserMessage {
    message: string, color?: string, textColor?: string
}

export interface IUserSignin {
    email: string | undefined
    password: string | undefined
    socialType: string | undefined
}

export enum DeviceType {
    TABLET = 'tablet',
    MOBILE = 'mobile',
    DESKTOP = 'desktop'
}

export enum MeMutations {
    PAYMENT_METHOD = 'mutatePaymentMethod',
    UPDATE_SUBSCRIPTION = 'mutateSubscription',
    UPDATE_ME = 'mutateMe',
    UPDATE_CARD = 'mutateCard',
}

export interface SubscriptionOptions {
    price_id: string;
    interval: string;
    billing_scheme: string;
    metadata: Metadata;
    recurring: Recurring;
    type: string;
    unit_amount: number;
    coupon_applied: boolean;
  }
  
  interface Recurring {
    aggregate_usage?: any;
    interval: string;
    interval_count: number;
    usage_type: string;
  }
  
  interface Metadata {
    web_banner: string;
    web_description: string;
    web_display_name: string;
    web_price: string;
    web_discount_price: string
  }

export enum FetchUserQueryUtilEnum {
  FETCH_FILTERING_STATUS = 'fetchFilteringStatus'
}