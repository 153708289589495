import { ColorPallet, KeyWarpper, KeysWrap } from "./ColorKey.styles"

const ColorKey: React.FC = () => {

    const Keys = [
        {name: 'Click Copy', color: '#b3f5ff'},
        {name: 'Navigate', color: '#66ccff'},
        {name: 'Details Only', color: '#8899A6'},
        {name: 'Column Name', color: '#C270c0;'},
    ]

    return <KeyWarpper>
        {Keys.map((key, index) => (
            <KeysWrap key={index}>
                <ColorPallet color={key.color}/>
                <span>{key.name}</span>
            </KeysWrap>
        ))}

    </KeyWarpper>
}

export default ColorKey