import { InfoRounded } from '@mui/icons-material';
import styled from 'styled-components/macro';
import * as theme from '../../../theme';
import { Button } from '@mui/material';

export const MyAccountWrapper = styled.div`
  margin: auto;
  display: flex;
  min-height: 100vh;
  justify-content: ${(props) => (props.addMarginTop ? '' : 'center')};
  flex-direction: column;
  align-items: center;
  height: calc(100% - 70px);
  margin-top: ${(props) => (props.addMarginTop ? '65px' : '')};
  padding: 0 20px;
  font-family: 'Avenir', 'Avenir-Condensed';
  background: #fff;
`;
export const AccountWrapper = styled.div`
  margin-top: 50px;
  max-width: 480px;
  text-align: left;
  width: 100%;
  @media ${theme.breakpoint.md} {
    margin: 0px;
    margin-top: 80px;
  }
`;
export const Header = styled.h1`
  font-size: 28px;
  color: ${props =>  props.isAdminUpdate ? '#CC3333':'#000000'};
  font-weight: 900;
  @media ${theme.breakpoint.md} {
    margin: 0px;
    font-size: 34px;
  }
`;

export const DetailBox = styled.div`
  border: 3px solid #999999;
  border-radius: 3px;
  margin-top: ${(props) => (props.noMargin ? '0px' : '40px')};
  .MuiTextField-root {
    width: 100%;
    input {
      color: white;

    }
    svg {
      fill: white;
    }
  }
`;

export const HeaderBox = styled.div`
  height: 20px;
  padding: 10px 20px;
  background-color: #4e4e65;
  color: white;
  font-size: 18px;
  font-family: 'Avenir', 'Avenir-Condensed';
`;
export const DetailContent = styled.div`
  padding: 19px 19px;
  font-size: clamp(10px, 4vw, 18px);
  color: black;
  display: flex;
  justify-content: space-between;
  align-items: center;
  span {
    display: flex;
    b {
      color: black;
    }
  }
  @media ${theme.breakpoint.md} {
    font-size: 22px;
  }
`;


export const Canceled = styled.span`
  color: #999999;
`;

export const InfoIcon = styled(InfoRounded)`
  margin-right: 5px;
  height: 30px;
  width: 30px;
`;

export const Break = styled.div`
  border-bottom: 3px solid #4e4e65;
`;
export const EditIcon = styled.img`
  :hover {
    cursor: pointer;
  }
  color: #66ccff;
  font-size: 20px;
  margin-left: 30px;
  width: 35px;
  height: 35px;
`;

export const EditText = styled.div`
  :hover {
    cursor: pointer;
  }
  color: #66ccff;
  margin-left: 5px;
  display: flex;
  img {
    max-height: 20px;
    margin-left: 5px;
    margin-top: 5px;
    padding-right: 5px;
  }
`;

export const Info = styled.p`
  font-size: 22px;
  max-width: 388px;
`;
export const SendAgain = styled.p`
  font-size: 22px;
  max-width: 388px;
  color: #999999;
  cursor: pointer;
  align-self: flex-end;
`;

export const Bold = styled.span`
   color: ${(props) => props.past && '#999999 !important' };
  font-weight: 900;
  color: white;
  align-self: right;
`;

export const ContentWrapperRow = styled.div`
  color: white;
  max-width: 800px;
  display: flex;
  flex-direction: row;
  svg {
    margin-right: 15px;
  }
`;
export const ContentWrapperCol = styled.div`
  color: white;
  max-width: 800px;
  display: flex;
  flex-direction: column;
`;

export const BackAndContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
`;

export const BackHomeButton = styled(Button)`
&& {
  margin-top: 30px;
  background-color: transparent;
  text-transform: none;
  color: #66ccff;
  font-size: 22px;
  box-shadow: 0px 3px 6px #00000041;
  border: 1px solid #707070;
  border-radius: 6px;
  opacity: 1;
  padding: 20px;
  max-width: 420px;
  width: 100%;
  align-self: center;
}
`;
export const HeaderInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;
export const InfoMax = styled(Info)`
  max-width: 550px;
`;
export const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;
`;
export const SmallLabel = styled.p`
  font-size: 18px;
  font-weight: 300;
  color: white;
  text-align: left;
  margin-bottom: 5px;
`;

export const CancelMembership = styled(Button)`
&&{

  background-color: transparent;
  text-transform: none;
  color: #cc3333;
  font-size: 18px;
  box-shadow: 0px 3px 6px #00000041;
  border: 1px solid #707070;
  border-radius: 6px;
  opacity: 1;
  padding: 20px;
  max-width: 420px;
  width: 100%;
  align-self: center;
}
`;

export const StayButton = styled(Button)`
&&{

  /* background: #4b4e5a 0% 0% no-repeat padding-box; */
  border: 3px solid #4e4e65;
  border-radius: 6px;
  font-size: 18px;
  color: white;
  width: 100%;
  height: 68px;
  text-transform: none;
  font-weight: bolder;
  margin-bottom: 20px;
  &:hover {
    background-color: transparent;
  }
}
`;
export const CancelButton = styled(Button)`
&&{
  background: #4e4e65 0% 0% no-repeat padding-box;
  border-radius: 6px;
  font-size: 18px;
  color: white;
  width: 100%;
  height: 68px;
  text-transform: none;
  font-weight: bolder;
  &:hover {
    background-color: #4e4e65;
  }

}
`;

export const CancelWrapper = styled.div`
  text-align: center;
`;

export const BlueLink = styled.span`
  color: #66ccff;
  size: 18px;
  &:hover {
    cursor:pointer;
  }
`;


export const AlignLeftDiv = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
`;

export const CanceledBox = styled.div`
  height: 283px;
  background: #4e4e65 0% 0% no-repeat padding-box;
  border-radius: 3px;
  padding: 0 50px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  color: white;
`;
export const H3 = styled.h3`
  font-size: 22px;
  color: white;
  font-weight: 900;
  margin: 0;
  margin-right: 10px;
`;
export const ChangeYourMindBox = styled.div`
  margin-top: 75px;
  background-color: transparent;
  font-size: 18px;
  box-shadow: 0px 3px 6px #00000041;
  border: 1px solid #707070;
  border-radius: 6px;
  opacity: 1;
  width: 100%;
  align-self: center;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  color: white;
  padding: 10px 0 0 0;
  p {
    text-align: left;
    margin: 3px 20px;
  }
`;
export const RestartMembership = styled(Button)`
  background-color: transparent;
  text-transform: none;
  color: #66ccff;
  font-size: 18px;
  box-shadow: 0px 3px 6px #00000041;
  border-top: 1px solid #707070;
  border-radius: 6px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  opacity: 1;
  height: 80px;
  padding: 20px;
  width: 100%;
  align-self: center;
  margin-top: 10px;
`;
export const SmallSub = styled.span`
  font-size: 20px;
`;

export const SocialImage = styled.img`
width: 100px;
margin-left: 10px;
margin-top: -3px;
`

export const Spacer = styled.div`
margin-top: ${props => props.small ? '90px' : '180px'};
`

export const BackToSearch = styled.div`
color: #000000;
margin-bottom: 15px;
&:hover{
    cursor: pointer;
    color: #999999;
}
`
export const MinorText = styled.span`
font-size: 14px;
margin-left: 10px;
align-self: center;
color: white;
`
export const Terms = styled.div`
margin-top: 15px;
font-size: 12px;
color: white;
text-align: center;
span {
  &:hover {
    cursor: pointer;
  }
}
`
export const PastDue = styled.div`
  font-size: 18px;
  color: #ff9933;
`

export const GrayWrapper = styled.div`
   color: ${(props) => props.past && '#999999 !important' };
   display: flex;
`

export const Logout = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 40px;
  color: #000000;
  font-size: 22px;
  :hover {
    cursor: pointer;
    color: #999999;
  }
  margin-bottom: 30px;
`;