import styled from "styled-components/macro";
import * as theme from "../../../theme";

export const ListWrapper = styled.div`
  width: 100%;
  margin-bottom: min(max(15px, 3vw), 35px);
  font-family: "Avenir" !important;
  border: ${(props) => props.focused && "2px solid yellow"};
  position: relative;
  overflow: hidden;
  #home-swiper {
    overflow-x: auto;
    overflow-x: hidden;
    .swiper-wrapper {
    }
    .empty-slide {
      width: 9%;
    }
    .first-slide-margin {
      margin-left: 9%;
    }
    .next {
      width: 9%;
      @media (min-width: 520px) {
        width: 7.7%;
      }
      position: absolute;
      z-index: 9;
      height: 100%;
      top: 0;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      background: transparent;
      transition: all 0.5;
      img {
        width: 10px;
        display: none;
        @media (min-width: 520px) {
          width: 17px;
        }
      }
      &:hover {
        transition: all 0.5;
        img {
          display: block;
        }
      }
      &.hidden {
        display: none;
      }
    }
    .prev {
      width: 9%;
      position: absolute;
      z-index: 9;
      height: 100%;
      top: 0;
      left: 0px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      background: transparent;
      transform: rotate(180deg);
      transition: all ease;
      img {
        width: 10px;
        display: none;
        @media (min-width: 520px) {
          width: 17px;
        }
      }
      &:hover {
        transition: all 0.5;
        img {
          display: block;
        }
      }
      &.hidden {
        display: none;
      }
    }
  }
`;

export const Category = styled.div`
  font-family: "Avenir-Condensed";
  font-size: min(max(16px, 2vw), 28px);
  color: #fff;
  font-weight: bold;
  padding-left: 9%;

  cursor: ${(props) => props.isAdmin && "pointer"};
  @media (min-width: 520px) {
    padding-left: 9%;
  }
  margin-bottom: 10px;
  div {
    max-width: 250px;
    background: linear-gradient(
      -90deg,
      rgba(78, 78, 101, 0.1) 0%,
      rgba(240, 240, 240, 0.1) 50%,
      rgba(78, 78, 101, 0.1) 100%
    );
  }
`;

export const CategoryTitle = styled(Category)`
  text-align: center;
  padding: 0;
  font-size: 30px;
  @media ${theme.breakpoint.lg} {
    font-size: 40px;
  }
`;

export const AssetWrapper = styled.div`
  position: relative;
  height: 100%;
  .share-link {
    height: 0;
    color: transparent;
    display: ${(props) => props.mobile && "none !mportant"};
  }
  &:hover {
    .share-link {
      position: absolute;
      bottom: -20px;
      left: 0px;
      height: 30px;
      color: white;
      z-index: 99;
      width: fit-content;
      background: rgba(0, 0, 0, 0.8);
      padding: 10px;
      border-radius: 8px;
      font-weight: 900;
      cursor: pointer;
      border-left: 4px solid rgba(255, 255, 255, 0.8);
      border-right: 4px solid rgba(255, 255, 255, 0.8);
      border-top: 4px solid rgba(255, 255, 255, 0.8);
      transition: 500ms all;
    }
  }
  .video-content {
    width: 95% !important;
    height: 100% !important;
    border-radius: 3px !important;
    #player {
      height: 100% !important;
      .vp-video-wrapper {
        height: 100% !important;
      }
    }
  }
`;

export const ArrowBox = styled.div`
  position: fixed;
  right: 0;
`;

export const TopRightOverlay = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  width: 55px;
  height: 55px;
  background-color: rgba(0, 0, 0, 0.05);

  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: white;
  z-index: 99999999;
`;

export const IframeAssetWrapper = styled.div`
  position: relative;
  height: 100%;
  width: 95%;
  margin-right: 1%;
  .video-content {
    background-color: #ececec;
    width: 95% !important;
    height: 99% !important;
    border-radius: 3px !important;
    #player {
      height: 100% !important;
      .vp-video-wrapper {
        height: 100% !important;
      }
    }
  }
`;
