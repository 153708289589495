import { IframeAssetWrapper } from './SliderRow.styles'

interface IUniqueFilm {
    ref: React.RefObject<any>
    focused: boolean
    film: any
    index: number
}

const UniqueFilm: React.FC<IUniqueFilm> = ({ref, focused, film, index}) => {
    return <IframeAssetWrapper
    ref={ref}
    key={index + film.asset_id}
    focused={focused}
  >
    <iframe
      className='video-content'
      title={film.name}
      id='iFrame'
      src={film.description}
      width='640'
      height='960'
      allow='autoplay; fullscreen; picture-in-picture'
    ></iframe>
      </IframeAssetWrapper>
}

export default UniqueFilm