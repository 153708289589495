import { useHistory } from 'react-router-dom'
import { AppRoutes } from '../../app/routes'
import { BackToAccountLink } from './BackToAccount.styles'




export const BackToAccount:React.FC = () =>{
    const history = useHistory()
    return (
        <BackToAccountLink onClick={() => history.push({pathname: AppRoutes.MY_ACCOUNT})}> &lt; Back to Account</BackToAccountLink>
    )
}

export const GoBack :React.FC = () =>{
    const history = useHistory()
    return (
        <BackToAccountLink onClick={() => history.goBack()}> &lt; Back</BackToAccountLink>
    )
}