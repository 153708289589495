import { Close } from "@mui/icons-material";
import styled from "styled-components/macro";
import { Colors } from "../theme";
import { Dialog } from "@mui/material";

export const HomeWrapper = styled.div`
  padding-top: 0;
  min-height: fit-content;
  padding-bottom: 380px;
  max-width: 2250px;
  margin: auto;
  background: linear-gradient(
    0deg,
    rgba(49, 50, 63, 1) 0%,
    rgba(49, 50, 63, 1) 70%,
    rgba(0, 0, 0, 1) 100%
  );
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  height: 100%;
`;
export const BgContainer = styled.div`
  background: linear-gradient(
    0deg,
    rgba(49, 50, 63, 1) 0%,
    rgba(0, 0, 0, 1) 85%,
    rgba(0, 0, 0, 1) 100%
  );
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  height: 200px;
  width: 100%;
  position: absolute;
  top: 0;
`;
export const HomeContainer = styled.div`
  min-height: fit-content;
  /* padding-top: 60px; */
  width: 100%;
  margin: auto;
  @media (min-width: ${(props) => props.loggedin === "true" ? "1080px" : "1150px"}) {
    padding: 0;
  }

  .down-message {
    display: flex;
    flex-direction: row;
    a {
      margin-left: 10px;
    }
  }

  @media (max-width: ${(props) => props.loggedin === 'true' ? '600px' : '1150px'} ) {
    #mobile-tabs {
      display: flex;
      -webkit-box-pack: justify;
      justify-content: flex-end;
      flex-direction: row;
      max-width:${(props) => props.loggedin === 'true' ?' 200px' : '400px'};
      z-index: 9999;
      position: fixed;
      width: 100%;
      right:   ${(props) => props.loggedin === 'true' ?' 20%' : '45%'};
      top: 16px;
    }
  }
  @media (max-width: ${(props) => props.loggedin === 'false' && '550px'} ) {
    #mobile-tabs {
      background-color: black;
      padding: 6px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      max-width:400px;
      z-index: 9999;
      position: fixed;
      margin-left: 0;
      width: fit-content;
      left: 0;
      margin-left: 9vw;
      top: 50px;
      background-color: rgb(9, 11, 19);
      border-radius: 6px;
    }
  }
`;

export const CustomerMessage = styled.div`
  height: 22px;
  text-align: center;
  letter-spacing: 0px;
  color: #000000;
  height: clamp(60px, 15vw, 102px);
  background: #ffffff 0% 0% no-repeat padding-box;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: clamp(10px, 2.5vw, 22px);
  margin-bottom: 40px;
  position: fixed;
  right: 0;
  top: 70px;
  width: 100%;
  z-index: 9;
`;

export const ListItem = styled.div`
  width: 60px;
  height: 60px;
  background-color: red;
`;

export const LoaderWrapper = styled.div`
  margin-top: 150px;
`;

export const ExtensionDialog = styled(Dialog)`
  display: flex;
  justify-content: center;
  align-items: center;

  .MuiPaper-root {
    max-width: 665px;
    border-radius: 30px;
  }
`;

export const InnerWrapper = styled.div`
  padding: 80px;
`;
export const DialogInner = styled.div`
  font-size: 30px;
`;

export const ExtensionLink = styled.div`
  color: ${Colors.colorBlue};
  font-weight: 900;
  font-size: 35px;
  text-align: center;
  margin-top: 40px;
  cursor: pointer;
`;
export const CloseButton = styled(Close)`
  position: absolute;
  top: 20px;
  right: 20px;
  font-weight: 900;
  cursor: pointer;
`;
