import algoliasearch from "algoliasearch/lite";
import { InstantSearch} from "react-instantsearch";
import SearchPage from './SearchPage';
import { useMemo } from "react";



const AlgoliaSearchWrapper: React.FC = () => {

  const searchClient = useMemo(() => {
    return algoliasearch(
      process.env.REACT_APP_ALGOLIA_APP!,
      process.env.REACT_APP_ALGOLIA_KEY!
    );
  }, []);
  return (
    <InstantSearch
      searchClient={searchClient}
      indexName="assets"
      insights={true}
    >
      <SearchPage />
    </InstantSearch>
  );
};

export default AlgoliaSearchWrapper
