import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CircularProgress from '@mui/material/CircularProgress';
import './MembershipModal.scss';

interface MembershipModalProps {
  open: boolean;
  onClose: () => void;
  onAction: () => void;
  onCancel?: () => void;
  title: string;
  content: string;
  primaryActionText: string;
  secondaryActionText: string;
  linkText?: string;
  linkHref?: string;
  loading: boolean;
  setLoading: (loading: boolean) => void;
}

const MembershipModal: React.FC<MembershipModalProps> = ({
  open,
  onClose,
  onAction,
  onCancel,
  loading,
  setLoading,
  title,
  content,
  primaryActionText,
  secondaryActionText,
  linkText,
  linkHref,
}) => {

  const handlePrimaryAction = () => {
    setLoading(true);
    onAction();
  };

  const handleSecondaryAction = () => {
    if (onCancel) {
      setLoading(true);
      onCancel();
    } else {
      onClose();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="membership-dialog-title"
      aria-describedby="membership-dialog-description"
      classes={{ paper: 'modalContent' }}
    >
      <DialogTitle id="membership-dialog-title" className="modalTitle">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="membership-dialog-description" className="modalContentText">
          {content}
          {linkText && linkHref && (
            <a href={linkHref} className="modalLink">{linkText}</a>
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions className="modalActions">
        <div onClick={handleSecondaryAction} className="modalButton">
          {secondaryActionText}
        </div>
        <div onClick={handlePrimaryAction} className="modalButton" autoFocus>
          {loading ? <CircularProgress size={24} /> : primaryActionText}
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default MembershipModal;
