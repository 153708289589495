import { MutableRefObject, useCallback, useEffect, useRef, useState } from 'react';
import { useCookies } from 'react-cookie';
import { AppRoutes } from '../app/routes';




export const useEventListener = (
    eventName: string,
    handler: (event: Event) => void,
    element: HTMLElement | Window = window
) => {
    const savedHandler = useRef<(event: Event) => void | null>(null) as MutableRefObject<any>;

    useEffect(() => {
        savedHandler.current = handler;
    }, [handler]);

    useEffect(() => {
        const isSupported = element && element.addEventListener;
        if (!isSupported) return;

        const eventListener = (event: Event) => savedHandler.current?.(event);
        element.addEventListener(eventName, eventListener);

        return () => {
            element.removeEventListener(eventName, eventListener);
        };
    }, [eventName, element]);
};

export const useExtensionInstall = () => {
    const [, setCookie] = useCookies(['extension_post_install_url']);
    const extensionInstall = useCallback(() => {
        setCookie(
            'extension_post_install_url',
            `${process.env.REACT_APP_REDIRECT}${AppRoutes.HOME}`,
            { domain: `${process.env.REACT_APP_DOMAIN}` }
        );
        window.open(`${process.env.REACT_APP_EXTENSION_URL}`, '_self');
    }, [setCookie]);

    return extensionInstall;
};

export const  useKeyboardInput = () => {
  const [isUsingKeyboard, setIsUsingKeyboard] = useState(false);

  useEffect(() => {
    function handleKeydown() {
      if (!isUsingKeyboard) {
        setIsUsingKeyboard(true);
        document.body.classList.add('using-keyboard');
      }
    }

    function handleMousemove() {
      if (isUsingKeyboard) {
        setIsUsingKeyboard(false);
        document.body.classList.remove('using-keyboard');
      }
    }

    window.addEventListener('keydown', handleKeydown);
    window.addEventListener('mousemove', handleMousemove);

    // Cleanup event listeners on component unmount
    return () => {
      window.removeEventListener('keydown', handleKeydown);
      window.removeEventListener('mousemove', handleMousemove);
    };
  }, [isUsingKeyboard]);

  return isUsingKeyboard;
}

