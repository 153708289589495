import jwtDecode from 'jwt-decode';
import { deleteAllCookies } from './userUtil';


const CLEARPLAY_SESSION_TOKEN = 'CLEARPLAY_SESSION_TOKEN'
export enum LocalStorage {
    REDIRECT_URL = 'REDIRECT_URL',
    CLEARPLAY_EXTENSION = 'CLEARPLAY_EXTENSION',
    CLEARPLAY_SUMMER_MOVIE = 'CLEARPLAY_SUMMER_MOVIE',
    CLEARPLAY_SEARCH_TOKEN = 'CLEARPLAY_SEARCH_TOKEN',
    SHUFFLE_DATE = 'SHUFFLE_DATE',
    TICKET_SHELF_ORDER = 'TICKET_SHELF_ORDER',
    CLEARPLAY_SESSION_TOKEN = 'CLEARPLAY_SESSION_TOKEN',
    CLEARPLAY_PAGE_PREF = 'CLEARPLAY_PAGE_PREF',
    CP_PLAN = 'CP_PLAN',
    CP_S_INTENT_T = 'CP_S_INTENT_T',
    CP_PRE_LOGIN_ACCOUNT_TYPE = 'CP_PRE_LOGIN_ACCOUNT_TYPE',
    WELCOME_PATH = 'WELCOME_PATH',
    CLICKED_SIGNUP = 'CLICKED_SIGNUP',
    REFERRAL_SOURCE = 'REFERRAL_SOURCE',
    PROMO_CODE = 'PROMO_CODE',
    CUSTOMER_ID_EDIT = 'CUSTOMER_ID_EDIT',
    UTM_SOURCE = 'UTM_SOURCE'
}

// getters

export const isLoggedIn = async () => {
    const token = await getTokenData()
    const isLoggedIn = !!token
    return isLoggedIn
}

export const getTokenData = async(): Promise<string | null | undefined> => {
    const sessionToken = localStorage.getItem(CLEARPLAY_SESSION_TOKEN)
    if (sessionToken && isTokenExpired(sessionToken)) {
      removeTokenData()
      deleteAllCookies()
      window.location.pathname = '/enter-email'
      return
    } else if (sessionToken) {
        return sessionToken
    }
}

export const getLocalStorageItem = (itemToGet: string) => {
    const item = localStorage.getItem(itemToGet)
    return item
}

// setters

export const setTokenData = async (authToken: string) => {
     localStorage.setItem(
        CLEARPLAY_SESSION_TOKEN, authToken
    )
}
export const setLocalStorageItem = (itemToSetName: string, item: string): any => {
     localStorage.setItem(
        itemToSetName, item
    )
}


// removers
export const removeLocalStorageItem = (itemToRemove: string): any => {
     localStorage.removeItem(
        itemToRemove
    )
}

export const removeTokenData = () => {
    localStorage.removeItem(CLEARPLAY_SESSION_TOKEN)
}

export const RemoveLocalStorageItems = () => {
  localStorage.clear()
}

const audKeys = ['EhZ55wZizy1hNvMgAzKWxAcha5cPTFJg']


const isAudence = (decoded: any) => {
  if (audKeys.includes(decoded.aud) || audKeys.includes(decoded.azp)) return true
  else return false
}

const isTokenExpired = (token: string) => {
    try {
      const decoded: any | undefined = token ? jwtDecode(token) : undefined
      let isExpired = false
      if (decoded) {
        if (Date.now() >= decoded.exp! * 1000) {
          isExpired = true
        }
        if (!isExpired && !isAudence(decoded)) {
          isExpired = true
        }
      }
      return isExpired
    } catch {
      return false;
    }
  };
