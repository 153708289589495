import { memo, useEffect, useState } from "react";
import {
  displayProviderMap,
  HitObject,
  Platform,
  providerMap,
} from "../../app/app.types";
import "./SearchImage.styles";
import {
  BorderNameWrapper,
  Checkmark,
  ImageProviderWrapper,
  MessageTooltip,
  PlatformLink,
  ProviderOverlay,
  SearchImageWrapper,
  Stream,
} from "./SearchImage.styles";
import checkmark from "../../assets/images/checkmark.svg";
import { NoUrl } from "./PosterImage.styles";
import PosterImage from "./PosterImage";
import TagManager from "react-gtm-module";
import { filteringDownMessage, filteringDownProvider } from "../../admin/utils";
import { useFeature } from "../../hooks/useFeature";
import { DeviceType, FetchUserQueryUtilEnum } from "../user/userTypes";
import { queryClient } from "../../lib/react-query";
import { Platform as FilteringPlatform } from "../../features/adminFeatures/tables/tableTypes";
import { userDeviceSiganl } from "../user/userSignals";

interface SearchImageProps {
  loading: boolean;
  setLoading: Function;
  film: HitObject;
}

const SearchImage: React.FC<SearchImageProps> = ({
  loading,
  film,
  setLoading,
}) => {
  const [clickedOnMobile, setClickedOnMobile] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const { goToLink } = useFeature();
  const filteringDown = queryClient.getQueryData<FilteringPlatform[]>([
    FetchUserQueryUtilEnum.FETCH_FILTERING_STATUS,
  ]);
  const isMobile = userDeviceSiganl.value !== DeviceType.DESKTOP;

  const providerClick = (
    provider: number,
    platform: Platform,
    assetType: string
  ) => {
    const platformStatus = filteringDownProvider(filteringDown, provider);
    if (loading) {
      return;
    } else if (platformStatus?.web_search === false) {
      setShowMessage(true);
      return;
    } else {
      const flix = {
        video_identifier: platform.partner_identifier,
        type_id: assetType,
        platform_title: platform.platform_title,
        platform_url: platform.platform_url,
      };
      TagManager.dataLayer({
        dataLayer: {
          eventCategory: `web.clearplay.com | ${providerMap[provider]}`,
          eventAction: "click event | all links",
          eventLabel: `${film.display_name}`,
          event: "interactionEvent",
        },
      });

      goToLink(provider, flix as any, true);
    }
  };
  useEffect(() => {
    if (!film?.img_URL) {
      setLoading(false);
    }
  }, [film.img_URL]);

  const setOverlayMobile = () => {
    if (!isMobile || clickedOnMobile) return;
    else {
      setClickedOnMobile(true);
    }
  };

  const getNumPlatforms = () => {
    const num = film.platforms.length;
    if (num === 2) {
      return "two-platforms";
    } else if (num === 3) {
      return "three-platforms";
    } else if (num === 4) {
      return "four-platforms";
    } else return "";
  };

  return (
    <SearchImageWrapper
      loading={loading.toString()}
      onClick={() => setOverlayMobile()}
      data-insights-filter={`${film.platforms}:${film.display_name}`}
    >
      <ProviderOverlay className="provider-overlay">
        {isMobile ? (
          <Stream style={{ fontSize: "10px" }}>Available:</Stream>
        ) : (
          <Stream>Stream on:</Stream>
        )}
        <ImageProviderWrapper
          isMobile={isMobile}
          className={`${getNumPlatforms()}`}
        >
          {film.platforms.map((p: any, i: number) => (
            <BorderNameWrapper
              isMobile={isMobile}
              clickedOnmobile={clickedOnMobile}
              onClick={() =>
                providerClick(p.platform_id, p, film.asset_type_id)
              }
              key={i}
            >
              <MessageTooltip
                displayMessage={
                  filteringDownProvider(filteringDown, p.platform_id)
                    ?.web_search === false
                }
                open={showMessage}
                onClose={() => setShowMessage(false)}
                title={
                  <div>
                    {filteringDownMessage(filteringDown, p.platform_id)}
                  </div>
                }
                arrow
              >
                <PlatformLink isMobile={isMobile}>
                  <span>{displayProviderMap[p.platform_id]}</span>
                  {!isMobile && <span className="underline" />}
                  {isMobile && <Checkmark src={checkmark} />}
                </PlatformLink>
              </MessageTooltip>
            </BorderNameWrapper>
          ))}
        </ImageProviderWrapper>
      </ProviderOverlay>
      {film.img_URL ? (
        <PosterImage setLoading={setLoading} asset={film} isHovered={false} />
      ) : (
        <NoUrl>{film.display_name}</NoUrl>
      )}
    </SearchImageWrapper>
  );
};

export default memo(SearchImage);
