import { NoCell, PulsingText, YesCell } from '../CustomCell.styles';

interface IPlatformYesNo {
  row: any;
  value: string;
  column: any;
}

export const PlatformYesNo: React.FC<IPlatformYesNo> = ({
  row,
  value,
  column,
}) => {

  if (row.sGrouped) return <span />;
  return (
    <span>
      {value ? (
        <YesCell>Yes</YesCell>
      ) : column.id === 'img_URL' ? (
        <PulsingText>No</PulsingText>
      ) : (
        <NoCell>No</NoCell>
      )}
    </span>
  );
};
