import styled from "styled-components/macro";
import { Colors } from "../../../theme";
import { FormControl, Select } from "@mui/material";

export const ScorDropDownTable = styled(Select)`
  && {
    .MuiSelect-select {
      color: ${Colors.colorBlue};
      div {
        min-width: 25px;
      }
    }
  }
  svg {
    color: ${Colors.colorBlue};
  }
`;

export const DropdownScoreControl = styled(FormControl)`
  && {
    min-width: 55px;
    label {
      width: 100px;
      color: ${Colors.colorBlue};
    }
  }
`;
