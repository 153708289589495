import {signal} from '@preact/signals-react'
import { DeviceType, Roles } from './userTypes';

  const getDeviceType = () => {
    const ua = navigator.userAgent;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
     return  DeviceType.TABLET
    } else if (
      /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
        ua
      )
    ) {
     return  DeviceType.MOBILE
    } else {
     return DeviceType.DESKTOP
    }
  }


export const userLoadingSignal = signal(false)
export const hasExtension = signal<boolean>(false)
export const openExtensionDialog = signal<boolean>(false)
export const userDeviceSiganl = signal(getDeviceType())
export const userUUIDSignal = signal('')
export const userMessageSignal = signal({message: '', color: 'red'})
export const userIsAmdinSginal = signal(false)
export const userIsSupportSignal = signal(false)
export const userRolesSignal = signal<Roles[]>([])
export const userLoggedInSignal = signal(false)

