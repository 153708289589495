import { memo, useRef } from "react";
import {
  FailedImgText,
  FailedUrl,
  Placeholder,
  StyledImage,
} from "./PosterImage.styles";
import { useFeature } from "../../hooks/useFeature";
import { Asset } from "../adminFeatures/assets/assetTypes";
import { HitObject } from "../../app/app.types";

interface PosterImageProps {
  setLoading: Function;
  isHovered: boolean;
  asset: Asset | HitObject
}

const PosterImage: React.FC<PosterImageProps> = ({
  setLoading,
  asset,
  isHovered,
}) => {
  const refPlaceholder: any = useRef();
  const refErrorPlaceHolder: any = useRef();
  const refImage: any = useRef();

  const isAsset = (asset: Asset | HitObject): asset is Asset => {
    return (asset as Asset).img_url !== undefined;
  };

  const imgUrl = isAsset(asset) ? asset.img_url : asset.img_URL;
  

  const removePlaceholder = () => {
    setLoading(false);
    refPlaceholder.current?.remove();
  };

  const onError = () => {
    setLoading(false);
    refPlaceholder.current?.remove();
    refImage.current!.style!.display = "none";
    refErrorPlaceHolder.current!.style!.display = "flex";
  };

  const changeImage = () => {
    if (asset.display_name === "One does Not simply walk Into Mordor") {
      return imgUrl;
    } else return false;
  };



  if (!imgUrl)
    return (
      <FailedUrl ref={refErrorPlaceHolder}>
        <FailedImgText>{asset.display_name}</FailedImgText>
      </FailedUrl>
    );


  return (
    <>
      <Placeholder ref={refPlaceholder} />
      <FailedUrl ref={refErrorPlaceHolder}>
        <FailedImgText>{asset.display_name}</FailedImgText>
      </FailedUrl>
      <StyledImage
        width="10px"
        height="100%"
        className="lazy"
        change={changeImage}
        ref={refImage}
        onLoad={removePlaceholder}
        onError={onError}
        src={imgUrl}
        alt={asset?.display_name}
      />
    </>
  );
};

export default memo(PosterImage);
