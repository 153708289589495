import axios, { AxiosRequestConfig } from 'axios';
import { removeTokenData, getTokenData } from '../utils/localStorageService';
import { AppRoutes } from './routes';
import { userMessageSignal } from '../features/user/userSignals';

export enum HTTP_METHOD {
  GET = 'GET',
  POST = 'POST',
  PATCH = 'PATCH',
  DELETE = 'DELETE'
}

export enum API_TYPE {
  CLEARPLAY = 'clearplayURL',
  AUTH0 = 'auth0Domain',
  STRIPE = '',
  CLEARPLAY_API = 'clearplayAPI',
  FILTER_API = 'filterAPI'
}

const API_BASE_URL_MAP: any = {
  [API_TYPE.CLEARPLAY]: process.env.REACT_APP_CLEARPLAY_FILTER_API,
  [API_TYPE.AUTH0]: process.env.REACT_APP_AUTH0_DOMAIN,
  [API_TYPE.CLEARPLAY_API]: process.env.REACT_APP_CLEARPLAY_PHP_API,
  [API_TYPE.FILTER_API]: process.env.REACT_APP_CLEARPLAY_NODE_API,
};

const getApiBaseUrl = (api: string) => {
  const baseUrl = API_BASE_URL_MAP[api];
  if (!baseUrl) {
    console.error('Error getting domain for API type:', api);
    return ''; // or throw an error if you want to handle missing base URLs as errors
  }
  return baseUrl;
};

// Handle unauthorized access
const handleUnauthorized = () => {
  removeTokenData();
  window.location.pathname = AppRoutes.ENTER_EMAIL;
};

const httpClient = axios.create();

export const apiRequest = async (
  method: HTTP_METHOD,
  api: string,
  urlPath?: string,
  body?: any,
  customHeaders?: any
) => {
  try {
    const token = await getTokenData(); 
    const headers = {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${token}`
    };

    const request: AxiosRequestConfig = {
      url: `${getApiBaseUrl(api)}${urlPath}`,
      method: method,
      data: body,
      headers: customHeaders ? { ...headers, ...customHeaders } : headers
    };

    const res = await httpClient.request(request);
    return res.data;
  } catch (error: any) {
    if (error && error.response && (error.response.status === 401 || error.response.status === 404)) {
      handleUnauthorized();
      userMessageSignal.value = { message: error.message, color: 'red' };
      return Promise.reject((error.response));
    }
    return Promise.reject((error.response));
  }
};
