import styled from 'styled-components/macro';
import { InfiniteHits } from 'react-instantsearch';

export const SearchPageWrapper = styled.div`

`;

export const ResultsWrapper = styled(InfiniteHits)`
  ol {
    li {
      list-style-type: none;
      img {
        border-radius: 6px;
      }
    }
    padding: 40px 20px;
    display: flex;
    flex-wrap: wrap;
    margin: auto;
    justify-content: ${(props) => (props.mobile ? 'center' : 'flex-start')};
    padding-left: 9vw;
  }
  button {
    display: none;
  }
`;
