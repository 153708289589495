import dayjs from "dayjs"
import { User } from "../user/userTypes"
import { getSocialUserType } from "../../utils/userUtil"
import { SubscriptionStatus } from "../../pages/account/myAccount/MyAccount"

export const ConstructCustomerUser = async(data: any): Promise<User> => {
    const user: User = {
      expMonth: data?.stripe?.card.expMonth,
      expYear: data?.stripe?.card.expYear,
      last4: data?.stripe?.card.last4,
      brand: data?.stripe?.card.brand,
      firstName: data?.user.given_name,
      lastName: data?.user.family_name,
      email: data?.user.email,
      cancelAtPeriodEnd: data?.stripe?.cancelAtPeriodEnd,
      subscription: data?.stripe?.subscription,
      periodEndDate: data?.stripe?.periodEndDate ? data?.stripe?.periodEndDate : SubscriptionStatus.NoSubscription,
      loginType: getSocialUserType(data?.user.user_id),
      canceled: data?.stripe?.cancelAtPeriodEnd && dayjs(data?.stripe?.periodEndDate).isBefore(dayjs()),
      user_id: data?.user.user_id,
      nextBilling: data?.stripe?.nextBillingDate,
      substatus: data?.stripe?.statuses[0],
      created_at: data?.user.created_at,
      phone: data?.user.user_metadata?.contact?.phone || '' ,
      status: getStatus(data?.stripe?.active, data?.stripe?.periodEndDate, data?.stripe?.card.last4, data?.stripe?.cancelAtPeriodEnd),
      hasStripe: data?.user?.app_metadata?.stripe?.id ? true : false,
      stripeId: data?.user?.app_metadata?.stripe?.id,
      amount: data?.stripe?.nextBillingAmount,
      interval: formatInterval(data?.stripe?.interval),
      discounted: data.stripe?.discounted,
      testMode: data.stripe.testMode,
      churnkeyHash: data.stripe.churnkeyHash,
      activeCouponMessage: data.stripe.activeCouponMessage,
      isActive: data.stripe.isActive,
      trialAvailable: data.stripe.trialAvailable
    }
    return user
  }

  const getStatus = (active: boolean, periodEndDate: string, card: string | undefined, cancelAtPeriodEnd: boolean) => {
    if (active && !card && !cancelAtPeriodEnd) {
      return 'active_noCard'
    } else if (active && !cancelAtPeriodEnd) {
      return 'active'
    } else if (active && cancelAtPeriodEnd && dayjs(periodEndDate).isAfter(dayjs())) {
        return 'canceled'
    } else if (!active && !periodEndDate) {
      return 'No plan selected'
    } else return 'active'
  }

  const formatInterval = (interval: string) => {
    if (interval === 'year') {
      return 'Annual '
    } else return 'Monthly'
  }