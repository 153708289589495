import { useCallback, useEffect } from "react";
import { useToggle } from "../../hooks/useToggle";
import { CustomSwitch } from "./CustomCell.styles";
import { updatePlatform } from "../../features/adminFeatures/tables/table.api";
import { userMessageSignal } from "../../features/user/userSignals";
import { useUser } from "../../features/user/useUser";

interface ToggleComponentProps {
  initialValue: boolean;
  column: any;
  row: any;
  setValue: React.Dispatch<React.SetStateAction<boolean>>;
  updateMyData: Function;
  value: boolean;
}

const ToggleComponent: React.FC<ToggleComponentProps> = ({
  initialValue,
  column,
  row,
  setValue,
  updateMyData,
  value,
}) => {
  const [toggleValue, toggle] = useToggle(value, [true, false]);
  const toggleSwitch = () => {
    toggle();
  };
  const {fetchUserFilteringStatus} = useUser()

  const updatePlatformCallback = useCallback(async () => {
    const res = await updatePlatform({
      platform_id: row.original.platform_id,
      [column.id === "isSearchable" ? "searchable" : column.id]: toggleValue,
    });
    if (res) {
      updateMyData(row.index, column.id, toggleValue);
      userMessageSignal.value = { message: `Changes Saved`, color: "green" };
      fetchUserFilteringStatus()
    } else {
      toggle();
      userMessageSignal.value = { message: `Error updating`, color: "red" };
    }
  }, [toggleValue]);

  useEffect(() => {
    if (toggleValue === value) return;
    setValue(toggleValue);
    updatePlatformCallback();
  }, [toggleValue]);

  return (
    <CustomSwitch
      sx={{ m: 1 }}
      id={`${column.id}-${row.id}`}
      onClick={() => toggleSwitch()}
      checked={value}
    />
  );
};

export { ToggleComponent };
