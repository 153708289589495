import styled, { keyframes } from 'styled-components/macro';

const KeyboardFocus = () => {
  return `
  transform: scale(1.009, 1.009) translateZ(0px) translate3d(0px, 0px, 0px);
  transition-duration: 100ms;
  transition-property: transform, box-shadow;
  transition-timing-function: ease-out;
  border-radius: 7px;
  ::after {
    border:  4px solid rgba(255, 255, 255, 0.8);
    inset: 0px;
    content: '';
    position: absolute;
    transition: border 300ms ease-out 0s;
    border-radius: 3px;
  }
  `
}

export const fade = keyframes`

0% {
      opacity: 0;
    }
100% {
      opacity: 1;
    }
`

export const ImageWrapper = styled.div`
  height: 100%;
  aspect-ratio: 2/3;
  overflow: hidden;
  backface-visibility: hidden;
  border-radius: 4px;
  cursor: ${(props) => (props.loading === 'true' ? '' : 'pointer')};
  pointer-events: ${(props) => (props.loading === 'true' ? 'none' : '')};
  display: block;
  transform: scale(1, 1) translateZ(0px);
  transition-duration: 100ms;
  transition-property: transform, box-shadow;
  transition-timing-function: ease-out;
  border: ${props => props.focus && '2px solid yellow'};
  ${props => props.focused === 'true' && !props.isMobile && KeyboardFocus}
  @media (hover: hover) and (pointer: fine) {
    &:hover {
      animation: ${fade} 1 ease-in-out;
      transform: scale(1.009, 1.009) translateZ(0px) translate3d(0px, 0px, 0px);
      transition-duration: 100ms;
      transition-property: transform, box-shadow;
      transition-timing-function: ease-out;
      border-radius: 7px;
      ::after {
        border: ${(props) =>
         props.isSearch ? '0' : ' 4px solid rgba(255, 255, 255, 0.8);'};
        inset: 0px;
        content: '';
        position: absolute;
        transition: border 300ms ease-out 0s;
        border-radius: 3px;
      }
      img {
        aspect-ratio: 2/3;
      }
    }
  }
`;



export const ProviderDown = styled.div`
  display: ${props => props.hidden ? 'none' : 'flex'};
  background: white;
  position: absolute;
  z-index: 9999;
  top: 50%;
  left: -16px;
  background-color: rgba(255,255,255, 0.95);
  padding: 10px 15px;
  font-size: clamp(9px, .9vw, 25px);
border-radius: 10px;
  line-height: 1;
  color: #000;
  width: 15vw;

  @media(min-width:  900px) {
  line-height: 1.5;
  border-radius: 20px;
    width: 18vw;
    left: -50px;
    padding: 15px 20px;
    top: 60%;
  }
`;



