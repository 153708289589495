export enum AppRoutes {
  // Customer site
  HOME = '/',
  HOME_EXPLORE = '/movies',
  SEARCH = '/search',
  ENTER_EMAIL = '/enter-email',
  ENTER_PASSWORD = '/enter-password',
  SIGNUP_EMAIL = '/signup-email',
  SIGNUP_PASSWORD = '/signup-password',
  SIGNUP_PAYMENT = '/signup-payment',
  MY_ACCOUNT = '/my-account',
  CHECK_EMAIL = '/check-email',
  NEW_EMAIL = '/new-email',
  NEW_PASSWORD = '/new-password',
  CONFIRMATION_SCREEN = '/confirmation',
  MEMBERSHIP_AND_PAYMENT = '/account-membership-payment',
  SIGNIN_CALLBACK = '/signin',
  MOBILE_EXPLAINER = '/mobile-explainer',
  ACCOUNT_FOUND = '/account-found',
  COLLECT_EMAIL = '/add-email',
  JOIN_CLEARPLAY = '/join',
  MOVIE_PAGE = '/movie-page',
  ABANDONED_CHECKOUT = '/complete-signup',

  // Admin Portal
  ADMIN_HOME = '/admin-home',
  ADMIN_DASHBOARD = '/admin-dashboard',
  ASSET_DETAIL_TABLE = '/asset-detail-table',
  ASSET_DETAIL_PAGE = '/asset-detail',
  ADMIN_ACCCOUNT_MANAGEMENT = '/admin-account-management',
  ADMIN_MANAGE_SHELF = '/admin-manage-shelf/:id',
  ADMIN_TEST_ACCOUNTS = '/admin/test-accounts',
  ADMIN_LINKS = '/admin/links',
  ADMIN_PLATFORM_CHANELS = '/admin/platform/channels'
}
