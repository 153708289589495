import React, { memo, useEffect, useMemo, useRef, useState } from "react";
import {
  MyAccountWrapper,
  Header,
  AccountWrapper,
  BackToSearch,
  DetailBox,
  DetailContent,
  EditIcon,
  Logout,
  EditText,
  SocialImage,
} from "./MyAccount.Styles";
import { useHistory } from "react-router-dom";
import { AppRoutes } from "../../../app/routes";
import {
  getLocalStorageItem,
  isLoggedIn,
  LocalStorage,
  removeLocalStorageItem,
  RemoveLocalStorageItems,
  setLocalStorageItem,
} from "../../../utils/localStorageService";
import { BackToAccountLink } from "../../../features/components/BackToAccount.styles";
import { SocialTypes, deleteAllCookies } from "../../../utils/userUtil";
import { useCookies } from "react-cookie";
import { useUser } from "../../../features/user/useUser";
import { useSetAtom } from "jotai";
import { initPreLoginUser, preLoginUser } from "../../../app/store";
import { useAdminData } from "../../../features/adminFeatures/useAdminData";
import { useQueryClient } from "@tanstack/react-query";
import {
  getStripeAccountUrl,
  getStripeSignupUrl,
  restartSubscription,
} from "../../../features/user/userApi";
import edit from "../../../assets/images/edit.svg";
import { UserStatus } from "../../../features/user/userModel";
import MembershipModal from "./components/MembershipModal";
import { userLoadingSignal } from "../../../features/user/userSignals";
import google from "../../../assets/images/google-dark.svg";
import facebook from "../../../assets/images/facebook-dark.svg";

export enum SubscriptionStatus {
  Active = "active",
  NoSubscription = "No plan selected",
  Canceled = "canceled",
  Active_NoCard = "active_noCard",
}
declare global {
  interface Window {
    churnkey: any;
  }
}

const truncateString = (str: string, num: number) => {
  if (str.length <= num) {
    return str;
  }
  return str.slice(0, num) + "...";
};

const MyAccount: React.FC = () => {
  const isMounted = useRef(true);
  const queryClient = useQueryClient();
  const history = useHistory();
  const { useFetchCustomer } = useAdminData();
  const redirect = getLocalStorageItem(LocalStorage.REDIRECT_URL);
  const [, removeCookie] = useCookies(["clearplay_token"]);
  const customerId = getLocalStorageItem(LocalStorage.CUSTOMER_ID_EDIT);
  const isAdminUpdate = customerId ? true : false;
  const { useMe } = useUser();
  const { data: me, isFetching, refetch: fetchMe } = useMe();
  const setUserData = useSetAtom(preLoginUser);
  const { data: customerData, refetch: fetchCustomer } = useFetchCustomer(
    customerId || ""
  );
  const [openChangeModal, setOpenChangeModal] = useState(false);
  const [openRestartModal, setOpenRestartModal] = useState(false);
  const cancelButtonRef = useRef<HTMLDivElement | null>(null);
  const [modalLoading, setModalLoading] = useState(false);

  const user = useMemo(() => {
    return isAdminUpdate ? customerData! : me!;
  }, [isAdminUpdate, customerData, me]);

  const clickBack = () => {
    if (!isAdminUpdate) {
      const redirectUrl = JSON.parse(JSON.stringify(redirect));
      setLocalStorageItem(LocalStorage.REDIRECT_URL, "");
      if (redirectUrl) {
        window.location.assign(redirectUrl);
      } else {
        history.push(AppRoutes.HOME);
      }
    }
  };

  const exitAccountManagement = () => {
    removeLocalStorageItem(LocalStorage.CUSTOMER_ID_EDIT);
    history.replace(AppRoutes.MY_ACCOUNT);
    fetchMe();
  };

  const logout = async () => {
    setUserData(initPreLoginUser);
    RemoveLocalStorageItems();
    removeCookie("clearplay_token", { domain: ".clearplay.com" });
    deleteAllCookies();
    queryClient.clear();
    setTimeout(() => {
      window.location.pathname = "/";
    }, 500);
  };

  const goToStripe = async (type?: string) => {
    let url;

    if (type === "billing") {
      url = await getStripeAccountUrl();
      window.open(url, "_self");
      return;
    }
    if (user.status === UserStatus.Active) {
      userLoadingSignal.value = true;
      url = await getStripeAccountUrl();
      url = url + `/subscriptions/${user.subscription}/update`;
    } else if (user.status === UserStatus.ActiveCanceling) {
      await restartSubscription(user.subscription);
      return await fetchMe();
    } else {
      userLoadingSignal.value = true;
      url = await getStripeSignupUrl();
    }
    userLoadingSignal.value = false;
    window.open(url, "_self");
    setModalLoading(false);
    setOpenChangeModal(false);
    setOpenRestartModal(false);
  };

  const initializeChurnkey = () => {
    setOpenChangeModal(false);
    if (!user?.churnkeyHash || user?.churnkeyHash === "disabled") return;
    window.churnkey.init("show", {
      customerId: `${user.stripeId}`,
      authHash: user.churnkeyHash,
      appId: "rsc1si2ny",
      mode: user.testMode === true ? "test" : "live",
      provider: "stripe",
      onError: () => {
        setTimeout(() => {
          window.churnkey.hide();
        });
      },
      onClose: async () => {
        window.location.pathname = AppRoutes.MY_ACCOUNT;
      },
    });
  };

  useEffect(() => {
    const handleButtonClick = () => {
      initializeChurnkey();
    };

    const cancelButtonElement = cancelButtonRef.current;
    if (cancelButtonElement) {
      cancelButtonElement.addEventListener("click", handleButtonClick);
    }

    return () => {
      if (cancelButtonElement) {
        cancelButtonElement.removeEventListener("click", handleButtonClick);
      }
    };
  }, [user?.churnkeyHash, user?.stripeId, user?.testMode]);

  useEffect(() => {
    if (!isMounted.current) return;
    isAdminUpdate && fetchCustomer();
    return () => {
      isMounted.current = false;
    };
  }, [isAdminUpdate]);

  useEffect(() => {
    if (!window.churnkey || !window.churnkey.created) {
      window.churnkey = { created: true };
      const script = document.createElement("script");
      script.src = "https://assets.churnkey.co/js/app.js?appId=rsc1si2ny";
      script.async = true;
      document.body.appendChild(script);
    }
  }, []);

  useEffect(() => {
    const isUserLoggedIn = async () => {
      const loggedIn = await isLoggedIn();
      if (!loggedIn) {
        history.push(AppRoutes.ENTER_EMAIL);
      }
    };
    isUserLoggedIn();
  }, [history, user]);

  const editLnk = (type: string) => {
    const page =
      type === "email" ? AppRoutes.NEW_EMAIL : AppRoutes.NEW_PASSWORD;
    return <EditIcon onClick={() => history.push(page)} src={edit}></EditIcon>;
  };

  const getMembershipButtonText = (status: UserStatus) => {
    switch (status) {
      case UserStatus.Active:
        return "Change";
      case UserStatus.ActiveCanceling:
        return "Restart";
      case UserStatus.CanceledTrialAvailable:
      case UserStatus.CanceledNoTrial:
        return "Sign Up";
      default:
        return "Change";
    }
  };

  const handleMembershipButtonClick = () => {
    switch (user.status) {
      case UserStatus.Active:
        setOpenChangeModal(true);
        break;
      case UserStatus.ActiveCanceling:
        setOpenRestartModal(true);
        break;
      case UserStatus.CanceledTrialAvailable:
      case UserStatus.CanceledNoTrial:
        goToStripe();
        break;
      default:
        setOpenChangeModal(true);
    }
  };

  const getLogo = (logo: string) => {
    if (logo === "google") {
      return google;
    } else return facebook;
  };

  const getIsSocialLogin = (loginType: string) => {
    if (
      loginType === SocialTypes.GOOGLE ||
      loginType === SocialTypes.GOOG ||
      loginType === SocialTypes.FACEBOOK
    ) {
      return true;
    } else return false;
  };

  return (
    <MyAccountWrapper adminMode={isAdminUpdate}>
      {user && !isFetching && (
        <AccountWrapper>
          {isAdminUpdate && (
            <BackToAccountLink onClick={() => clickBack()}>
              <span onClick={() => exitAccountManagement()}>
                &lt; Exit Account Management
              </span>
            </BackToAccountLink>
          )}

          {
            <BackToAccountLink onClick={clickBack}>
              {redirect ? (
                <span>&lt; Back to movie</span>
              ) : (
                <span>&lt; Back Home</span>
              )}
            </BackToAccountLink>
          }

          {isAdminUpdate && (
            <BackToSearch
              onClick={() => {
                removeLocalStorageItem(LocalStorage.CUSTOMER_ID_EDIT);
                history.push(AppRoutes.ADMIN_ACCCOUNT_MANAGEMENT);
              }}
            >
              &lt; Return to Customer Search
            </BackToSearch>
          )}

          <Header isAdminUpdate={isAdminUpdate}>
            {isAdminUpdate ? "Customer Account" : "Account"}
          </Header>
          <DetailBox>
            {getIsSocialLogin(user.loginType) && (
              <DetailContent>
                <span>
                  <span> Logged in with:&nbsp; </span>
                  <SocialImage
                    src={getLogo(user.loginType)}
                    alt={user.loginType}
                  />
                </span>
              </DetailContent>
            )}
            {user?.email && (
              <DetailContent>
                <span>
                  Email: &nbsp;<b>{truncateString(user?.email, 23)}</b>
                </span>{" "}
                {!getIsSocialLogin(user.loginType) && (
                  <span> {editLnk("email")}</span>
                )}
              </DetailContent>
            )}
            {!getIsSocialLogin(user.loginType) && (
              <DetailContent>
                <span>
                  {" "}
                  Password:&nbsp; <b>********</b>
                </span>{" "}
                <span> {editLnk("password")}</span>
              </DetailContent>
            )}
          </DetailBox>
          <DetailBox>
            <DetailContent>
              Billing Information
              <EditText onClick={() => goToStripe("billing")}>
                See Details
              </EditText>
            </DetailContent>{" "}
          </DetailBox>
          <DetailBox>
            <DetailContent>
              <span> Membership</span>{" "}
              <EditText onClick={handleMembershipButtonClick}>
                {getMembershipButtonText(user?.status as UserStatus)}
              </EditText>
            </DetailContent>{" "}
          </DetailBox>
          {!isAdminUpdate && <Logout onClick={() => logout()}>Log Out</Logout>}
        </AccountWrapper>
      )}
      <MembershipModal
        open={openChangeModal}
        onClose={() => setOpenChangeModal(false)}
        onAction={goToStripe}
        onCancel={initializeChurnkey}
        title="Membership Options"
        content="Having technical issues? "
        linkText="We can help!"
        linkHref="https://help.clearplay.com/"
        primaryActionText="Change Plan"
        secondaryActionText="Cancel Membership"
        loading={modalLoading}
        setLoading={setModalLoading}
      />
      <MembershipModal
        open={openRestartModal}
        onClose={() => setOpenRestartModal(false)}
        onAction={goToStripe}
        title="Restart Membership"
        content="Would you like to restart your membership?"
        primaryActionText="Restart"
        secondaryActionText="No thanks"
        loading={modalLoading}
        setLoading={setModalLoading}
      />
    </MyAccountWrapper>
  );
};

export default memo(MyAccount);
