import { signal } from '@preact/signals-react';
import { hasExtension } from '../user/userSignals';

export const extensionObserverDidRun = signal(false);

// Function to check for the presence of ClearPlayFilterRoot
function checkForElement() {
    return document.querySelector("#ClearPlayFilterRoot") !== null;
}

// Callback function to execute when mutations are observed
const callback = function(mutationsList: any, observer: { disconnect: () => void; }) {
    if (!observerActive) return;

    if (hasExtension.value === false && checkForElement()) {
        console.log("ClearPlayFilterRoot is present");
        hasExtension.value = !!document.querySelector("#ClearPlayFilterRoot");
        extensionObserverDidRun.value = true;
    }

    if (hasExtension.value) {
        observer.disconnect();
    }
};

// Flag to indicate if the observer is still active
let observerActive = true;

// Function to start observing the DOM
function startObserving() {
    // If we already have a value, don't start observing again
    if (hasExtension.value) return;

    const observer = new MutationObserver(callback);
    const config = { childList: true, subtree: true };
    observer.observe(document.body, config);

    // Stop observing after 10 seconds
    setTimeout(() => {
        observerActive = false;
        observer.disconnect();
        console.log("Observer disconnected after 10 seconds");
    }, 10000);
}

export const startExtensionMutationObserver = startObserving;
