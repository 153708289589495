import styled from 'styled-components/macro';
import { SearchBox } from 'react-instantsearch';

export const StyledSearchBox = styled(SearchBox)`
button {
  display: none;
}
input {
  position: fixed;
  top: 70px;
  z-index: 9;
  max-width: 2250px;
  width: 100%;
  height: clamp(70px, 10vw, 100px);
  background: rgba(46, 46, 60, 1) 0% 0% no-repeat padding-box;
  border: none;
  color: white;
  text-align: left;
  font-size: clamp(22px, 4vw, 42px);
  font-weight: 900;
  letter-spacing: 0px;
  color: #96969a;
  font-family: Avenir;
  /* padding-left: clamp(40px, 1vw, 80px); */
  padding-left: 9.5vw;
  padding-right: clamp(40px, 1vw, 80px);
  box-sizing: border-box;
  display: flex;
  align-items: center;
  &:focus-visible {
    outline: none;
  }
  @media (min-width:
       "1080px") {
       top: 40px;
    }

  
}

`;
