import { AxiosError } from 'axios';
import { QueryClient, UseQueryOptions, UseMutationOptions, DefaultOptions } from '@tanstack/react-query';
import { PromiseValue } from 'type-fest';
import { FetchFlixResponse } from '../features/adminFeatures/assets/assetTypes';
import { User } from '../features/user/userTypes';

const queryConfig: DefaultOptions = {
  queries: {
    // useErrorBoundary: true,
    refetchOnWindowFocus: false,
    retry: false,
    staleTime: Infinity
  },
};

export const queryClient = new QueryClient({ defaultOptions: queryConfig });

export type ExtractFnReturnType<FnType extends (...args: any) => any> = PromiseValue<
  ReturnType<FnType>
>;

export type QueryConfig<QueryFnType extends (...args: any) => any> = Omit<
  UseQueryOptions<ExtractFnReturnType<QueryFnType>>,
  'queryKey' | 'queryFn'
>;

export type MutationConfig<MutationFnType extends (...args: any) => any> = UseMutationOptions<
  ExtractFnReturnType<MutationFnType>,
  AxiosError,
  Parameters<MutationFnType>[0]
>;

export enum Queries {
  FETCH_FLIX = 'queryFlix',
  FETCH_ME = 'me'

}

interface QueryKeyTypes {
  [Queries.FETCH_FLIX]: FetchFlixResponse
  [Queries.FETCH_ME]: User
}

export function useTypedQueryData<T extends keyof QueryKeyTypes> (queryKey: T, param?: number | string) {
  const key = param !== undefined ? [queryKey, param] : [queryKey];
  return queryClient.getQueryData<QueryKeyTypes[T]>(key);
}