import { CustomTooltip } from "./HeaderTooltip.styled"


interface HeaderTooltipProps {
    title: string
    children: JSX.Element
}

const HeaderTooltip: React.FC<HeaderTooltipProps> = ({title, children}) => {
    return <CustomTooltip  title={title}>
        {children}
    </CustomTooltip>
}

export default HeaderTooltip