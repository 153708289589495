import { ApiTypes, Categories, Flix } from "../../app/app.types";
// import { TicketAsset } from "../components/shelfComponents/ContentShelf";

export interface FlixState {
  Netflix: {
    flix: Flix[];
    offset: number;
    take: number;
  };
  Amazon: {
    flix: Flix[];
    offset: number;
    take: number;
  };
  MAX: {
    flix: Flix[];
    offset: number;
    take: number;
  };
  Disney: {
    flix: Flix[];
    offset: number;
    take: number;
  };
  Apple: {
    flix: Flix[];
    offset: number;
    take: number;
  };
  PrimeRow: Flix[];
  status: "idle" | "loading" | "failed";
  categories: [Categories] | undefined;
  Provider: ApiTypes;
  category: string | boolean;
  loading: boolean;
  // ticketRow: TicketAsset[];
  providerTab: number;
  focusedAsset: FocusedAsset | null;
  focusKey: string | null;
}

export interface FocusedAsset {
  loading: boolean;
  value: number;
  film: Flix;
}


type PathToProviderIndexType = {
  [key: string]: number;
};

const pathToProviderIndex: PathToProviderIndexType = {
  "/amazon": 0,
  "/apple": 1,
  "/disney": 2,
  "/max": 3,
  "/netflix": 4,
  "/search" : 5
};

export function getCurrentPathIndex(path: string) {
  const searchParams = new URLSearchParams(window.location.search);
  const hasQuery = searchParams.has('q'); // Check if 'q' parameter is present

  // If there's a query 'q', consider it as a search, regardless of the path
  if (hasQuery) {
    return 5; // Return the index for "/search" provider
  }

  // Check if the path is directly in the map
  if (pathToProviderIndex.hasOwnProperty(path)) {
    return pathToProviderIndex[path];
  }

  // Default or not found case
  return -1; // or any other value you use to indicate "not found"
}

export const returnProviderFromIndex = (index: number) => {
  switch (index) {
    case 0: {
      return ApiTypes.AMAZON;
    }
    case 1: {
      return ApiTypes.APPLE;
    }
    case 2: {
      return ApiTypes.DISNEY;
    }
    case 3: {
      return ApiTypes.MAX;
    }
    case 4: {
      return ApiTypes.NETFLIX;
    }
    case 5: {
      return 100
    }
    default: {
      return ApiTypes.AMAZON;
    }
  }
};
export const returnIndexFromProvider = (provider: number) => {
  switch (provider) {
    case ApiTypes.AMAZON: {
      return 0
    }
    case ApiTypes.APPLE: {
      return 1
    }
    case ApiTypes.DISNEY: {
      return 2;
    }
    case ApiTypes.MAX: {
      return 3;
    }
    case ApiTypes.NETFLIX: {
      return 4;
    }
    case 100: {
      return 5
    }
    default: {
      return 0;
    }
  }
};


