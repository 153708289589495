import jwtDecode from 'jwt-decode';
import { Roles } from '../features/user/userTypes';
import { userIsAmdinSginal, userIsSupportSignal, userRolesSignal } from '../features/user/userSignals';

export interface IUserExists {
  email: string;
  name: string;
  user_id: string;
}
export enum SocialTypes {
  GOOGLE = 'google-oauth2',
  FACEBOOK = 'facebook',
  EMAIL = 'email',
  AUTH0 = 'auth0',
  GOOG = 'google'
}

export const SocialLoginTypes = [SocialTypes.GOOGLE, SocialTypes.FACEBOOK];

export const getSocialUserType = (data: string): string | any => {
  const google = data.match(/google/) || data.match(/google-oauth2/);
  const facebook = data.match(/facebook/);
  if (google) {
    return google[0];
  } else if (facebook) {
    return facebook[0];
  } else return 'email';
};
export const getTypeOfUser = (user: IUserExists): SocialTypes => {
  const socialType = getSocialUserType(user.user_id);
  if (socialType === SocialTypes.GOOGLE) {
    return SocialTypes.GOOGLE;
  } else if (socialType === SocialTypes.FACEBOOK) {
    return SocialTypes.FACEBOOK;
  } else return SocialTypes.EMAIL;
};

export const deleteAllCookies = () => {
  document.cookie.split(";").forEach((c) => {
    document.cookie = c
      .replace(/^ +/, "")
      .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
  });
};

export const useRoleDetector = () => {
  const roleDetector = (token: string) =>  {
    const decoded: any = jwtDecode(token);
    const roles = decoded[`https://clearplay.com/roles`];
    let rolesArray = [] as Roles[];
    if (roles && roles.length && roles.includes('Admin')) {
      rolesArray.push(Roles.ADMIN);
      userIsAmdinSginal.value = true
      userRolesSignal.value = rolesArray
    }
    if (roles && roles.length && roles.includes( 'CustomerSupport' )) {
      rolesArray.push(Roles.CUSTOMER_SUPPORT);
      userIsSupportSignal.value = true
      userRolesSignal.value = rolesArray

    }
    if (roles && roles.length && roles.includes( 'DevMode' )) {
      rolesArray.push(Roles.DEV_MODE);
      userIsSupportSignal.value = true
      userRolesSignal.value = rolesArray

    }
    if (roles && roles.length && roles.includes( 'Tester' )) {
      rolesArray.push(Roles.TESTER);
      userIsSupportSignal.value = true
      userRolesSignal.value = rolesArray

    }
  }
  return {roleDetector}
};

export const formatPhone = (phone: string) => {
  if (!phone) return
  const cleaned = ('' + phone).replace(/\D/g, '');
  
  // Handle 11-digit numbers (with a leading 1)
  if (cleaned.length === 11 && cleaned.startsWith('1')) {
    const match = cleaned.match(/^1(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}`;
    }
  }

  // Handle 10-digit numbers
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }

  return phone; 
}

