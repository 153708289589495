import TagManager from 'react-gtm-module';
import { apiProviderTypeHome } from '../../app/app.types';

export const posterImageObserver = () => {
  const lazyImages = [].slice.call(document.querySelectorAll('img.lazy'));

  if ('IntersectionObserver' in window) {
    let lazyImageObserver = new IntersectionObserver(function (entries) {
      entries.forEach(function (entry) {
        if (entry.isIntersecting) {
          let lazyImage: any = entry.target;
          lazyImage.src = lazyImage.dataset.src;
          lazyImage.srcset = lazyImage.dataset.srcset;
          lazyImage.classList.remove('lazy');
          lazyImageObserver.unobserve(lazyImage);
        }
      });
    });

    lazyImages.forEach(function (lazyImage) {
      lazyImageObserver.observe(lazyImage);
    });
  } else {
    // Possibly fall back to event handlers here
  }
};

export const scrollPercentage = (providerTab: number) => {
  var h: any = document.documentElement,
    b: any = document.body,
    st: string = 'scrollTop',
    sh: string = 'scrollHeight';

  var percent = ((h[st] || b[st]) / ((h[sh] || b[sh]) - h.clientHeight)) * 100;
  if ([25, 50, 75, 100].includes(Number(percent.toFixed(0)))) {
    TagManager.dataLayer({
      dataLayer: {
        eventCategory: 'web.clearplay.com | all pages',
        eventAction: `scroll event | ${percent.toFixed(0)}`,
        eventLabel: `${apiProviderTypeHome[providerTab]}`,
        event: 'interactionEvent',
      },
    });
  }
};
