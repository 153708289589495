import { useHistory } from "react-router-dom";
import { AppRoutes } from "../../../app/routes";
import { LinkWrapper } from "./ButtonCell.styles";
import { useSetAtom } from "jotai";
import { adminAppState } from "../../../app/store";
import { fetchShelfList } from "../../../features/adminFeatures/tables/table.api";
import { providerMap } from "../../../app/app.types";

interface ButtonCellProps {
  text: any;
  row: any;
  columnId: string;
}

export const ButtonCell: React.FC<ButtonCellProps> = ({
  text,
  row,
  columnId,
}) => {
  const history = useHistory();
  const setAdminDrawerState = useSetAtom(adminAppState);
  const goToDetail = async (row: any) => {
    switch (columnId) {
      case "scoreDetail": {
        const rowValues = JSON.parse(JSON.stringify(row.values));
        return history.push({
          pathname: AppRoutes.ASSET_DETAIL_TABLE,
          state: { row: rowValues, type: "score" },
        });
      }
      case "total_votes": {
        const rowValues = JSON.parse(JSON.stringify(row.values));
        return history.push({
          pathname: AppRoutes.ASSET_DETAIL_TABLE,
          state: { row: rowValues, type: "score" },
        });
      }
      case "assetDetail": {
        const rowValues = JSON.parse(JSON.stringify(row.values));
        setAdminDrawerState((prev) => ({
          ...prev,
          adminDrawer: {
            open: true,
            values: rowValues,
            rowIndex: row.id,
          },
        }));
        break;
      }
      case "manageShelf": {
        const rowValues = JSON.parse(JSON.stringify(row.values));
        const rowValueWithAssets = await fetchShelfList(
          rowValues.asset_list_type_id
        );
        setAdminDrawerState((prev) => ({
          ...prev,
          adminDrawer: {
            open: false,
            values: rowValueWithAssets,
            rowIndex: row.id,
          },
        }));
        history.push(`/admin-manage-shelf/${rowValues.asset_list_type_id}`);
        break;
      }
      case "channels": {
        const rowValues = JSON.parse(JSON.stringify(row.values));
        history.push(`/admin/platform/channels#${providerMap[rowValues.platform_id]}`);
        break;
      }
      default: {
        break;
      }
    }
  };

  return <LinkWrapper onClick={() => goToDetail(row)}>{text}</LinkWrapper>;
};
