import { NoCell, YesCell } from '../CustomCell.styles';

interface IOnOffCell {
  value: boolean;
  column: any;
}

export const OnOffCell: React.FC<IOnOffCell> = ({
  value,
}) => {
  return (
    <span>
      {value === true ? (
       <YesCell>On</YesCell>
      ) : (
        <NoCell>Off</NoCell>
      )}
    </span>
  );
};
