import { Version } from './CustomCell.styles';

interface VersionInputProps {
  value: string;
  setValue: Function;
  onBlur: Function;
}

const VersionInput: React.FC<VersionInputProps> = ({
  value,
  setValue,
  onBlur,
}) => {
  return (
    <Version
      variant="standard"
      value={value}
      onChange={(e: any) => setValue(e.target.value)}
      onBlur={onBlur}
    />
  );
};

export { VersionInput };
