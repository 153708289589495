import { apiRequest, API_TYPE, HTTP_METHOD } from '../../app/api';
import { Flix } from '../../app/app.types';
import { Asset, FetchFlixResponse } from '../adminFeatures/assets/assetTypes';
import { fetchingSignal } from './useFlix';
// import { TicketAsset } from '../components/shelfComponents/ContentShelf';

export const getFlix = async (provider: number, offset: number, take: number) => {
  const flix = await apiRequest(HTTP_METHOD.GET, API_TYPE.CLEARPLAY, `/v1/search?platformId=${provider}&offset=${offset}&take=${take}`, {})
  return flix
}

// export const fetchTicketShelf = async (opts: { platformId: number }) => {
//   try {
//     const assets = await apiRequest(
//       HTTP_METHOD.POST,
//       API_TYPE.FILTER_API,
//       `/assets/shelf_name`,
//       {
//         "shelf_name": "Summer 22 Enjoy the Show",
//         "is_active": null,
//         "platform_id": opts.platformId,
//       }
//     );
//     return assets as TicketAsset[];
//   } catch (error) {
//     throw error;
//   }
// };

export const fetchFlix = async (opts: { provider: number, offset: number, take: number }) => {
  try {
    const res = await apiRequest(
      HTTP_METHOD.GET,
      API_TYPE.CLEARPLAY,
      `/v1/search?platformId=${opts.provider}&offset=${opts.offset}&take=${opts.take}`,
      {}
    );
    return res;
  } catch (error) {
    throw error;
  }
};

export const fetchPrimeRow = async () => {
  try {
    const res = await apiRequest(
      HTTP_METHOD.GET,
      API_TYPE.FILTER_API,
      `/assets/amazon_prime`
    );
    return res;
  } catch (error) {
    throw error;
  }
};

export const fetchInitShelves = async (platformId: number) => {
  try {
    const res = await apiRequest(
      HTTP_METHOD.GET,
      API_TYPE.FILTER_API,
      `/assets/shelves?platform_id=${platformId}`
    );
    return res;
  } catch (error) {
    throw error;
  }
}
export const fetchInitFlix = async ({provider, assets_limit, shelves_limit, shelves_offset}: {provider: number, assets_limit: number, shelves_limit: number, shelves_offset: number}): Promise<FetchFlixResponse> => {
  try {
    if (provider === 100) return {} as FetchFlixResponse
    const res = await apiRequest(
      HTTP_METHOD.GET,
      API_TYPE.FILTER_API,
      `/assets/shelves/combined?platform_id=${provider}&assets_offset=0&assets_limit=${assets_limit}&shelves_limit=${shelves_limit}&shelves_offset=${shelves_offset}`
    );
    return res;
  } catch (error) {
    throw error;
  }
}

// Function to fetch additional assets for a specific shelf
export const fetchAssetsForShelf = async ({ asset_list_type_id, platform_id, offset, limit }: { asset_list_type_id: number, platform_id: number, offset: number, limit: number }): Promise<Asset[]> => {
  try {
    const response = await apiRequest(
      HTTP_METHOD.GET,
      API_TYPE.FILTER_API,
      `/assets/shelves/assets?asset_list_type_id=${asset_list_type_id}&platform_id=${platform_id}&offset=${offset}&limit=${limit}`
    );
    return response; // Assuming this returns the assets
  } catch (error) {
    throw error;
  }
};
